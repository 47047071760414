import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IInputError, IInputVarient } from '../../models/Input';
import { Subscription } from 'rxjs'

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() name = '';
  @Input() inputId = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() type  = 'text';
  @Input() max: string | number = '';
  @Input() errors: IInputError[] = [];
  @Input() fieldControl!: FormControl;
  @Input() variant: IInputVarient  = IInputVarient.rounded;
  @Output() inputFocused = new EventEmitter();
  @Output() inputChanged = new EventEmitter();

  constructor() { }

  errorMessage = '';

  private statusChangesSubscription!: Subscription;

  ngOnInit(): void {
    this.enableValidation();
  }

  ngOnDestroy(): void {
    if (this.statusChangesSubscription) this.statusChangesSubscription.unsubscribe();
  }

  onBlurField() {
    this.errorMessage = this.getError();
  }

  private enableValidation() {
    this.statusChangesSubscription = this.fieldControl.statusChanges.subscribe(() => {
      if (this.fieldControl.invalid && this.fieldControl.touched) {
        this.errorMessage = this.getError();
        return;
      }
    });
  }

  private getError() {
    for (const error of this.errors) {
      if (!this.fieldControl.hasError(error.name)) continue;

      return error.message;
    }

    return '';
  }

  getCurrentError(){
    return this.getError();
  }

  getInputVarient(){
    switch (this.variant) {
      case IInputVarient.rounded:
      return 'rounded-input'

      default:
        return 'rounded-input';
    }
  }

  onFocus(event: Event){
    this.inputFocused.emit(event);
  }

  onChange(event: Event) {
    this.inputChanged.emit(event)
  }


}
