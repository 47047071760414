<div class="main">
  <div class="opaque-bg">
    <div class="dialog">
      <div class="dialog__component-header">
        <img style="cursor: pointer" src="assets/svg/exit-icon.svg" (click)="onClose()" />
      </div>
      <main>
        <h4>Are you sure you want to unsubscribe?</h4>
      </main>

      <div class="footer">
        <button class="default-button-outlined" (click)="onClose()" type="button">Cancel</button>
        <button class="{{!successUnsubscribe ? 'default-button' : 'default-button-disabled'}}"
          [disabled]="successUnsubscribe" (click)="onSubmit()">
          {{!isLoading ? "Confirm" : ""}}
          <img src="assets/gifs/curl-lab.gif" alt="curl lab gif" *ngIf="isLoading" />
        </button>
      </div>

      <div *ngIf="successUnsubscribe" class="success-feedback">
        <span>Success! You have successfully been unsubscribed!</span>
      </div>

      <div *ngIf="invalidUnsubscribe" class="invalid-feedback">
        <span>Error! Try again later!</span>
      </div>
    </div>
  </div>
</div>