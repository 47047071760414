import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IEndRecoverPasswordFormFields, IStartRecoverPasswordFormFields } from '../../models/Form';
import { LoginService } from 'src/app/core/services/login.service';
import { Router } from '@angular/router';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { PASSWORD_PATTERN } from '../../utils/regexPatterns';
import { validateAllFormFields } from '../../utils/validateAllFormFields';
import { IEndRecoverPassword, IRecoverPassword } from '../../models/RecoverPassword';
import { RecoverPasswordService } from 'src/app/core/services/recover-password.service';
import { Subscription } from 'rxjs'
import { Feedback } from '../../models/Feedback';
import { clearForm } from '../../utils/clearForm';
@Component({
  selector: 'app-end-recover-password',
  templateUrl: './end-recover-password.component.html',
  styleUrls: ['./end-recover-password.component.scss']
})
export class EndRecoverPasswordComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public endRecoverPasswordForm!: FormGroup<IEndRecoverPasswordFormFields>;
  public endRecoverPasswordFormControls!: IEndRecoverPasswordFormFields;
  public email: string = '';
  public passwordSend: boolean = false;
  public isLoading = false;
  public code = "";
  public userId: number | null = null;
  public isVisibleConfirmPassword = false;
  public isVisiblePassword = false;
  private recoverPasswordCodeSubscription = new Subscription();
  public hasFeedback = false;
  public feedbackMessage = "";
  public feedback = new Feedback();

  constructor(private loginService: LoginService, private router: Router, private authModalManagerService: AuthModalManagerService, private recoverPasswordService: RecoverPasswordService) {
  }

  ngOnInit(): void {
    this.endRecoverPasswordForm = this.initFormGroup();
    this.endRecoverPasswordFormControls = this.endRecoverPasswordForm.controls;

   this.recoverPasswordCodeSubscription =  this.recoverPasswordService.getRecoverPasswordCode().subscribe((recoverPassword) =>{
      if(recoverPassword){
        this.code = recoverPassword.code;
        this.userId = recoverPassword.userId;

        this.checkLinkValidity();
      }
    })

  }

  initFormGroup() {
    return this.endRecoverPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          PASSWORD_PATTERN
        )]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    {validators: [this.confirmPasswordValidatior]});
  }

  onSubmit() {
    this.feedback.hasFeedback = false;
    if(!this.userId || !this.code){
      this.feedback.setFeedback("The link is invalid", "error");
      return;
    }
    if(this.endRecoverPasswordForm.invalid ||  this.isLoading) {
      validateAllFormFields(this.endRecoverPasswordForm);
      return;
    };
    this.isLoading = true;

    const endRecoverPassword: IEndRecoverPassword = {
      newPassword: this.endRecoverPasswordFormControls.password.value,
      code: this.code,
      userId: this.userId
    }
    this.loginService
      .endRecoverPassword(endRecoverPassword)
      .subscribe({
        next: (response) => {
          this.feedbackMessage = response.payload.message;
          this.passwordSend = true;
          this.isLoading = false;
          this.feedback.setFeedback(response.payload.message, "success")
          clearForm(this.endRecoverPasswordForm);
        },
        error: (response)=> {
           this.passwordSend = true;
          this.isLoading = false;
          this.feedback.setFeedback(response.error.message ? response.error.message : "The link is invalid.", "error")
        }
      });
  }

  checkLinkValidity(){
    this.passwordSend = true;
    this.isLoading = true;

    if(!this.userId || !this.code){
      this.feedback.setFeedback("The link is invalid");
      this.isLoading = false;
      return;
    }

    const recoverPassword: IRecoverPassword = {
      code: this.code,
      userId: this.userId
    }

    this.loginService
    .checkLinkValidity(recoverPassword)
    .subscribe({
      next: (response) => {
        clearForm(this.endRecoverPasswordForm);
        this.passwordSend = false;
        this.feedback.hasFeedback = false;
        this.isLoading = false;
      },
      error: (response)=> {
        this.feedbackMessage = response.error.message ? response.error.message : "The link is invalid.";
        this.feedback.setFeedback(response.error.message ? response.error.message : "The link is invalid.", "error")
        this.passwordSend = true;
        this.isLoading = false;
      }
    });
  }

  closeRecoverPassword() {
    this.authModalManagerService.closeModal();
    this.router.navigate(["/uat"])
  }

  async finishRecoverPassword() {
    await this.router.navigate(['/uat']);
  }
  changeSignInView(){
    this.router.navigate(['/uat']);
    this.authModalManagerService.updateTypeModal('signIn');
  }

  closeModal(){
    this.authModalManagerService.closeModal();
  }
  confirmPasswordValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if ( password?.value === confirmPassword?.value ){
      confirmPassword?.setErrors({ notSame: null })
      confirmPassword?.updateValueAndValidity({onlySelf: true});
      return null;
    } else {
      confirmPassword?.setErrors({ notSame: true });
      return null;
    }
  };

  changeConfirmPasswordView(){
    this.isVisibleConfirmPassword = !this.isVisibleConfirmPassword;
  }
  changePasswordView(){
    this.isVisiblePassword = !this.isVisiblePassword;
  }

  ngOnDestroy(): void {
   this.recoverPasswordCodeSubscription.unsubscribe();
  }
}
