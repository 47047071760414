import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LoginService } from "../services/login.service";
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private loginService: LoginService) {}

    private interceptableApisUrls = [environment.apiUrl];

    isAnInterceptableRequest(requestUrl: string): Boolean {
        for (let apiUrl of this.interceptableApisUrls) {
            if (requestUrl.includes(apiUrl)) return true;
        }
        return false;
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const session = this.loginService.getSession();
        if (
            session &&
            Object.keys(session).length > 0 &&
            this.isAnInterceptableRequest(request?.url)
        ) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${session.token}`,
                },
            });
        }

        return next.handle(request);
    }
}
