import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UploadImage } from '../../models/UploadImage';

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
  public previewImage = '';

  @Output() uploadImageEvent = new EventEmitter<UploadImage>();

  onImageChange(event: any) {
    const image: File | null = event.target.files.item(0);

    if (image) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.previewImage = e.target?.result as string;
      };

      reader.readAsDataURL(image);

      this.uploadImageEvent.next({hasUploaded: true, image});
    }
  }
}
