<div class="dialog">
  <main>
    <div #signUpElement class="section sing-in">
      <h2>Sign in</h2>
      <form [formGroup]="subscriptionForm" enctype="multipart/form-data">
        <form-input
        [inputId]="'signInEmail'"
        [type]="'email'"
        [name]="'email'"
        [placeholder]="'Email address'"
        [fieldControl]="subscriptionFormControls.email"
        [errors]="[
        {
          name: 'required',
          message: 'Please enter an email address'
        },
        {
          name: 'pattern',
          message: 'Please enter an email address in the format email@domain.com'
        }]"></form-input>
        <form-input-password
        [inputId]="'signInPassword'"
        [name]="'password'"
        [placeholder]="'Password'"
        [fieldControl]="subscriptionFormControls.password"
        [errors]="[
        {
          name: 'required',
          message: 'Password is required'
        },
        ]"
        [isVisiblePassword]="isVisiblePassword"
        (onChangePasswordView)="changePasswordView()"
        ></form-input-password>
        <div class="form-end">
          <div id="forgot-password" class="text-center">
            <a class="link" style="cursor: pointer" (click)="goToRecoverView()">
              Forgot Password?
            </a>
          </div>
          <form-checkbox [inputId]="'checkbox-allow-contact'" [text]="'Remember Me'" [fieldControl]="subscriptionFormControls.rememberLogin"></form-checkbox>
        </div>

        <form-button [disabled]="subscriptionForm.invalid" class="sign-in" [isLoading]="isLoading" (onClick)="onSubmit()" type="'submit'" [text]="'Sign In'" [varient]="buttonTypes.contained"></form-button>
        <div class="w-100 d-flex justify-content-center mb-3">
          <feedback-text [fontWeight]="700" [varient]="feedback.varient" [hidden]="!feedback.hasFeedback" [text]="feedback.msg"> </feedback-text>
        </div>
      </form>

      <div class="facebook-button" (click)="loginWithFaceBook()">
        <img style="cursor: pointer" src="assets/svg/facebook_icon.svg" alt="facebook-icon" />
        Sign in with Facebook
      </div>
      <div #googleBtn id="googleBtn"></div>
      <div class="create-account" *ngIf="hasCreateAccountLink">
        <label>New in Curl Lab?</label>
        <form-button class="create-account__button" (onClick)="goToSignUpView()" [text]="'Create Account'" [varient]="buttonTypes.outlined"></form-button>
      </div>
      <label class="sign-in-footer">By continuing, you agree to Curl Lab's
        <a (click)="closeModal()" [routerLink]="['/uat/policy-terms']">Terms of Use and Privacy Policy</a></label>
    </div>
  </main>
</div>
