<div class="main">
  <div class="opaque-bg">
  </div>

  <div class="cart">
    <div class="cart__component-header">
      <h2>{{ currentCart?.items ? currentCart?.items?.length : 0 }} item(s) added to cart</h2>
      <img src="assets/svg/exit-icon.svg" (click)="closeCart()"/>
    </div>
    <br>
    <h2 class="cart__title">Order Summary</h2>

    <div class="divider-black"></div>
    <div id="scroller" *ngIf="!isLoading">
      <div class="cart__itens" *ngFor="let item of currentCart?.items; index as i">
        <div class="cart__itens__infos">
          <div class="cart__itens__infos__description">
            <img [src]="item.merchandise.image"/>
            <span id="product-name">{{ item.merchandise.name }}</span>
          </div>
          <span class="product-price">${{ item.unitPrice }}</span>
        </div>
        <div class="cart__itens__quantity">
          <span (click)="removeItem(item)">Remove</span>
          <app-quantity-input
            [currentQuantity]="item.quantity"
            (quantityChange)="onQuantityChange(item, $event)"
          ></app-quantity-input>
        </div>
        <div class="divider-gray"></div>
      </div>
      <div class="cart__subtotal">
        <div class="cart__subtotal__header">
          <span>Subtotal</span>
          <span class="product-price">
              $<span id="subtotal">{{ currentCart?.subtotal }}</span>
            </span>
        </div>
        <span class="cart__subtotal__info">
            Shipping & taxes calculated at checkout
          </span>
      </div>
      <div class="cart__continue">
        <button class="default-button" (click)="goToCheckout()">
          CONTINUE TO CHECKOUT
        </button>
      </div>
    </div>
    <div class="divider-gray"></div>
  </div>
</div>
