import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageHelper } from 'src/app/shared/common/Storage.helper';
import { ResponseModel } from 'src/app/shared/models/Response';
import { Session } from 'src/app/shared/models/Session';
import { User } from 'src/app/shared/models/User';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { FacebookLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
import { ILoginResponse } from 'src/app/shared/models/LoginResponse';
import { CartService } from './cart.service';
import { IEndRecoverPassword, IRecoverPassword, IStartRecoverPassword } from 'src/app/shared/models/RecoverPassword';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public static USER_API_URLS = {
    USER_LOGIN: `${environment.apiUrl}/user/login`,
  };

  private userSubject = new BehaviorSubject<User | undefined>(
    new User(this.localStorage?.getObject('user'))
  );
  private partialUserSubject = new BehaviorSubject<User | undefined>(undefined);
  private renderer: Renderer2;
  private environment: any = environment;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageHelper,
    private notificationService: NotificationService,
    private router: Router,
    private rendererFactory: RendererFactory2,
    private socialAuthService: SocialAuthService,
    private cartService: CartService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  login(credentials: any): Observable<ResponseModel<any>> {
    return this.http.post<ResponseModel<any>>(
      LoginService.USER_API_URLS.USER_LOGIN,
      credentials
    );
  }

  async loginWithFaceBook() {
    return await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
  }

  isLoggedIn(): boolean {
    return !!this.localStorage.get('user');
  }

  logout(force: boolean = false) {
    return this.http
      .post<ResponseModel<any>>(`${this.environment.apiUrl}/user/logout`, {})
      .subscribe(async (response) => {
        if (force) {
          this.notificationService.error(
            'Error: Session Expired! Please, authenticate again.'
          );
        }
        this.clearCache();
        this.setUser(undefined);
        localStorage.clear()
        localStorage.removeItem("user")
        localStorage.removeItem("session")
        localStorage.removeItem("roles")
        sessionStorage.clear();
        sessionStorage.removeItem("loginResponse");
        this.cartService.clearCart()
        await this.router.navigate(['/uat']);
      });
  }



  setUser(data: User | undefined) {
    if (data) {
      this.localStorage.setObject('user', data);
    }
    this.userSubject.next(data);
  }

  getUser(): Observable<User | undefined> {
    return this.userSubject.asObservable();
  }

  getCurrentUserValue(): User | undefined {
    return this.userSubject.getValue();
  }

  getPartialUser() {
    return this.partialUserSubject.asObservable();
  }

  setPartialRegisterUser(data: User) {
    this.userSubject.next(data);
  }

  setPartialUser(data: any) {
    this.partialUserSubject.next(new User(data));
  }

  setCache(data: ILoginResponse) {
    this.localStorage.setObject('session', data.session);
    this.localStorage.setObject('roles', data.roles);
    if (!this.getTheme()) {
      this.localStorage.set(`theme`, 'light');
    }
  }

  getTheme() {
    return this.localStorage.get(`theme`);
  }

  setTheme(theme: string) {
    this.localStorage.set(`theme`, theme);
    this.applyTheme(theme);
  }

  applyTheme(theme: string) {
    if (theme == 'dark') {
      this.renderer.addClass(document.body, theme);
    } else {
      this.renderer.removeClass(document.body, 'dark');
    }
  }

  toggleTheme() {
    this.setTheme(this.getTheme() === 'dark' ? 'light' : 'dark');
  }

  getSession() {
    if (this.localStorage.get('session')) {
      return new Session(this.localStorage.getObject('session'));
    }
    let session = this.getSessionFromLoginResponse();
    if (session) {
      return session;
    }
    return;
  }

  getSessionFromLoginResponse() {
    let session: string | null = sessionStorage.getItem('session');
    if (session && session !== "undefined") {
      return new Session(JSON.parse(session));
    }
    return;
  }

  clearCache() {
    this.localStorage.remove('user');
    this.localStorage.remove('session');
    this.localStorage.remove('roles');
  }

  createUser(data: any) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/register`,
      data
    );
  }

  validateEmail(code: string) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/email-validate`,
      { inviteCode: code }
    );
  }

  validateSMS(phone: string, eventCode: string, inviteCode: string) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/sms-validate`,
      { phone, eventCode, inviteCode }
    );
  }

  resendSMS(phone: string, inviteCode: string) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/resend-sms`,
      { phone, inviteCode }
    );
  }

  uploadImage(file: any, inviteCode: string) {
    const formData: FormData = new FormData();
    formData.append('image', file, file.name);
    formData.append('inviteCode', inviteCode);
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/upload-picture`,
      formData
    );
  }

  startForgotPassword(data: IStartRecoverPassword): Observable<ResponseModel<any>> {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/start-recover-password`,
      data
    );
  }

  endRecoverPassword(data: IEndRecoverPassword) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/recover-password`,
      data
    );
  }

  checkLinkValidity(data: IRecoverPassword) {
    return this.http.post<ResponseModel<any>>(
      `${this.environment.apiUrl}/user/check-link-validity`,
      data
    );
  }

  confirmLoginAndStore(loginResponse: ILoginResponse) {
    if (loginResponse) {
      this.setCache(loginResponse);
      this.setUser(loginResponse.user);
    }
  }
}
