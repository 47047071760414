import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Input, Output, ViewChild, OnChanges, } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/shared/models/User';
import { LoginService } from "../../../core/services/login.service";
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { ISeeResultsFormFields } from '../../models/Form';
import { validateAllFormFields } from '../../utils/validateAllFormFields';
import { clearForm } from '../../utils/clearForm';
import { EMAIL_PATTERN } from '../../utils/regexPatterns';
import { Feedback } from '../../models/Feedback';

@Component({
  selector: 'see-results',
  templateUrl: './see-results.component.html',
  styleUrls: ['./see-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeeResultsComponent implements OnInit, AfterContentInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() saveAnswers: EventEmitter<any> = new EventEmitter();
  @Input() openDialog: boolean = false;

  public userForm!: FormGroup<ISeeResultsFormFields>;
  public userFormControls!: ISeeResultsFormFields;
  public feedback = new Feedback();
  public isLoading = false;

  constructor(private userService: UserService,
    private loginService: LoginService,
    private router: Router,
    private authModalManagerService: AuthModalManagerService) {
  }
  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.userFormControls = this.userForm.controls;
  }

  onCloseDialog() {
    this.close.emit();
  }

  initFormGroup(): void {
    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required,Validators.minLength(2),Validators.maxLength(60)]),
      lastName: new FormControl('',[Validators.minLength(2),Validators.maxLength(60)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(
        EMAIL_PATTERN
      )]),
      acceptReceiveEmail: new FormControl(false)
    });
  }

  async onSubmit() {
    this.feedback.hasFeedback = false;

    if(this.userForm.invalid ||  this.isLoading) {
      validateAllFormFields(this.userForm);
      return;
    };
    this.isLoading = true;
    try {
      const user: User = await this.registerPartialUser();
      this.saveAnswers.emit(user)
      this.feedback.setFeedback('Success! We’ve just sent you a confirmation e-mail!', 'success');;
      clearForm(this.userForm);
    } catch (error) {
      this.feedback.setFeedback('Sorry, this email is already subscribed.', 'error');
    }
    this.isLoading = false;
  }

  async registerPartialUser(): Promise<User> {
    let user: User = {
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      acceptReceiveEmail: this.userForm.get('acceptReceiveEmail')?.value,
    };
    const subscribeUserCall = this.userService.partialRegister(user, true);
    let subscribedUser = await lastValueFrom(subscribeUserCall);

    if (subscribedUser.exception) {
      console.error(subscribedUser.exception)
    }

    this.loginService.setPartialUser(subscribedUser.payload);

    return subscribedUser.payload;
  }

  goToSignUpView() {
    this.authModalManagerService.openModal('signIn');
  }
}
