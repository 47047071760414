<div class="form-checkbox" [ngStyle]="{justifyContent: justifyContent}">
  <div class="form-checkbox__container" [ngStyle]="{alignItems: alignItens}">
    <div class="round">
      <input (click)="handleButtonClick($event)" class="rounded-input" type="checkbox" [id]="inputId" [formControl]="fieldControl"
        [value]="false" />
      <label [for]="inputId"></label>
    </div>
    <label [ngStyle]="{fontSize: fontSize + 'px'}" [for]="inputId" class="description">{{ text }}</label>
  </div>
</div>
