export const environment = {
  production: false,
  apiUrl: "https://api.dev.curllab.com/api",
  cmsUrl: "https://cms.dev.curllab.com",
  cmsToken: "c323195aa1eaa5177e4c7513fc4aa231e6818850577428c1aa215a57e0f9d7fc74320cb4d7e57f425b8d49053b67f289f6a6c988a1bce03b7c1951a378ab3364eba130755b45d51ffd0f0f7eab4675608b25e62d13697a224dea8932636da78f35aae10139419b0f6865a8df631efd8248eb6337f3fc7dc388e482754d56ab83",
  tagManagerId: 'GTM-PWRDMSQ',
  googleClientId: "626223920260-ebo8kn0t64orh8fc2q86rvekfac8na1e.apps.googleusercontent.com",
  facebookClientId: '3522450511319667',
  shopifyCustomizedShampooALId: '7868333949164',
  shopifyCustomizedShampooPVId: '7887220769004',
  shopifyCustomizedShampooMKId: '7887886876908',
  shopifyCustomizedShampooBCId: '7887887106284',
  shopifyCustomizedShampooPMId: '7887887565036',
  shopifyCustomizedConditionerALId: '7870835687660',
  shopifyCustomizedConditionerPVId: '7887887761644',
  shopifyCustomizedConditionerMKId: '7887887892716',
  shopifyCustomizedConditionerBCId: '7887887958252',
  shopifyCustomizedConditionerPMId: '7887888023788',
  shopifyCustomizedBalmALId: '7870836441324',
  shopifyCustomizedBalmPVId: '7887888417004',
  shopifyCustomizedBalmMKId: '7887888810220',
  shopifyCustomizedBalmBCId: '7887889203436',
  shopifyCustomizedBalmPMId: '7887889236204',
  shopifyCustomizedMaskALId: '7871324881132',
  shopifyCustomizedMaskPVId: '7887889531116',
  shopifyCustomizedMaskMKId: '7887889662188',
  shopifyCustomizedMaskBCId: '7887889727724',
  shopifyCustomizedMaskPMId: '7887889924332',
  shopifyPowerGelId: '7809662419180',
  shopifyRefreshingSprayId: '7809668940012',
  shopifyScalpSprayId: '7809667793132',
  shopifyMousseId: '7809670709484',
  shopifyGelatineId: '7809449754860',
  shopifyCreamId: '7809662877932',
  shopifyButterId: '7809666416876',
  shopifyOilId: '7809673658604',
  shopifyLocationId: 71303430380
};
