import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IInputError } from '../../models/Input';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-date-picker',
  templateUrl: './form-date-picker.component.html',
  styleUrls: ['./form-date-picker.component.scss']
})
export class FormDatePickerComponent implements OnInit {

  @Input() placeholder = '';
  @Input() fieldControl!: FormControl;
  @Input() errors: IInputError[] = [];
  @Input() inputId = '';
  @Input() name = '';
  @Input() max: string | number = '';
  type: 'text' | 'date' = 'text';

  constructor() { }

  ngOnInit(): void {}

  changeInputType(){
    this.type = 'date';
  }
}
