import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'feedback-text',
  templateUrl: './feedback-text.component.html',
  styleUrls: ['./feedback-text.component.scss']
})
export class FeedbackTextComponent implements OnInit {
  @Input() text: string = '';
  @Input() varient: 'error' | 'success' = 'error';
  @Input() fontSize: number = 12;
  @Input() fontWeight: number = 400;
  constructor() { }

  ngOnInit(): void {
  }

  getVarientClass() {
    return `feedback-text--${this.varient}`
  }
}
