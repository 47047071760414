import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {User} from "src/app/shared/models/User"
import {UpdatePersonalInfos} from 'src/app/shared/models/UpdatePersonalInfos';
import {ResponseModel} from 'src/app/shared/models/Response';
import {UserAddress} from 'src/app/shared/models/UserAddress';
import { LocalStorageHelper } from 'src/app/shared/common/Storage.helper';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public static USER_API_URLS = {
    USER_GOOGLE_LOGIN: `${environment.apiUrl}/user/google-login`,
    PARTIAL_REGISTER: `${environment.apiUrl}/user/partial-register`,
    USER_REGISTER: `${environment.apiUrl}/user/register`,
    EMAIL_CONFIRMATION: `${environment.apiUrl}/user/email-confirmation`,
    USER_FACEBOOK_LOGIN: `${environment.apiUrl}/user/facebook-login`,
    UPDATE_PERSONAL_INFORMATIONS: `${environment.apiUrl}/user/update-personal-information`,
    CREATE_ADDRESS: `${environment.apiUrl}/user/create-address`,
    GET_ACCOUNT_INFORMATIONS: `${environment.apiUrl}/user/get-account-information`,
    UPDATE_EMAIL_ME: `${environment.apiUrl}/user/update-email-me`,
  };

  constructor(private http: HttpClient,  private localStorage: LocalStorageHelper) {
  }

  getAccountInformations(): Observable<any> {
    return this.http.get(UserService.USER_API_URLS.GET_ACCOUNT_INFORMATIONS);
  }

  updateEmailMe(User: User): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.UPDATE_EMAIL_ME,
      User
    );
  }

  updatePersonalInformations(userPersonalInfos: UpdatePersonalInfos): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.UPDATE_PERSONAL_INFORMATIONS,
      userPersonalInfos
    );
  }

  createAddress(address: UserAddress): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.CREATE_ADDRESS,
      address
    );
  }

  partialRegister(user: User, returnExistent = false): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.PARTIAL_REGISTER,
      user,
      {params: {returnExistent}}
    );
  }

  googleLogin(credentials: any): Observable<ResponseModel<any>> {
    return this.http.post<ResponseModel<any>>(
      UserService.USER_API_URLS.USER_GOOGLE_LOGIN,
      credentials
    );
  }

  facebookLogin(credentials: any): Observable<ResponseModel<any>> {
    return this.http.post<ResponseModel<any>>(
      UserService.USER_API_URLS.USER_FACEBOOK_LOGIN,
      credentials
    );
  }

  userRegister(user: User): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.USER_REGISTER,
      user
    );
  }

  validateEmail(userId: string, code: string): Observable<any> {
    return this.http.post(
      UserService.USER_API_URLS.EMAIL_CONFIRMATION,
      {userId, code}
    );
  }

  getCurrentLoggedUser(){
    const result = this.localStorage.get('user');
    return JSON.parse(result);
  }
}
