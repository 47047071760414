import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TagManagerService } from './core/services/tag-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Curl Lab';

  constructor(private tagManagerService: TagManagerService){}

  ngAfterViewInit(){
    if(environment.production){
      this.tagManagerService.create(environment.tagManagerId);
    }
  }


}
