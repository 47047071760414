import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IRecoverPassword } from 'src/app/shared/models/RecoverPassword';

@Injectable({
  providedIn: 'root'
})
export class RecoverPasswordService {
  private recoverPasswordCode = new BehaviorSubject<IRecoverPassword | undefined>(undefined);

  constructor() { }

  setRecoverPasswordCode(recoverPassword: IRecoverPassword){
    this.recoverPasswordCode.next(recoverPassword);
  }

  getRecoverPasswordCode(){
    return this.recoverPasswordCode.asObservable();
  }
}
