import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loading-box',
  templateUrl: './loading-box.component.html',
  styleUrls: ['./loading-box.component.scss']
})
export class LoadingBoxComponent {
  @Input() isLoading = false;
}
