import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import * as showdown from 'showdown';
import { FavoriteService } from 'src/app/private/services/favorite.service';
import { NavbarService } from 'src/app/public/services/navbar.service';
import { CmsHelper } from 'src/app/shared/common/CmsHelper';
import { CmsMedia } from 'src/app/shared/models/cms/CmsMedia';
import { CmsObjectData } from 'src/app/shared/models/cms/CmsObejctData';
import { Favorite } from 'src/app/shared/models/Favorite';
import { ProductDetailed, ProductSize } from 'src/app/shared/models/Product';
import { ProductVariation } from 'src/app/shared/models/ProductVariation';
import { CartService } from 'src/app/core/services/cart.service';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
  @Input() public product!: ProductDetailed;
  @Input() public isQuizResult = false;
  @Input() colorImgId = 0;

  public rate = 0;
  public max = 5;
  public productSizes: CmsObjectData<ProductSize>[] = [];
  // public isReadonly = true;
  public totalApprovedReviews: number = 0;

  public favoriteId!: number | undefined;
  // public productId!: number;
  public readonly recurrences = [
    { code: 1, description: 'Every month' },
    { code: 2, description: 'Every week' },
    { code: 3, description: 'Every 2 months' },
    { code: 4, description: 'Every 3 months' },
  ];

  public currentShowcaseImg!: string;
  public addProductForm!: UntypedFormGroup;

  constructor(
    private navbarService: NavbarService,
    private favoriteService: FavoriteService,
    private router: Router,
    public cmsHelper: CmsHelper,
    private _formBuilder: UntypedFormBuilder,
    private cartService: CartService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentShowcaseImg = this.cmsHelper.getUrlFromCollectionMedias(
      this.product.images,
      this.colorImgId
    );
    this.initializeSizes();
    this.initializeAddProductForm();
    this.setupCurrentVariation();
    await this.initializeFavorite();
  }


  get productId() {
    return this.product.productId;
  }

  get form() {
    return this.addProductForm.controls;
  }

  get productProTip() {
    return (
      this.product.proTip ??
      this.product.usefulness[1]?.description.replace('HOW TO USE:\n', '')
    );
  }

  get isAddedToCart() {
    const productVariation = this.addProductForm.get("selectedVariation")?.value;
    if(productVariation){
      return this.cartService.checkProductInCart(productVariation);
    }
    return false;
  }

  initializeAddProductForm() {
    this.addProductForm = this._formBuilder.group({
      buyOption: new FormControl('once', [Validators.required]),
      recurrence: new FormControl(null),
      selectedVariation: new FormControl<CmsObjectData<ProductVariation> | null>(null, [Validators.required]),
      quantity: new FormControl(1, [Validators.required]),
    });
    this.addProductForm.get('recurrence')?.disable();
  }

  async initializeFavorite() {
    const apiCall = this.favoriteService.getAllForUser();
    const apiResponse = await lastValueFrom(apiCall);

    if (!apiResponse || apiResponse?.exception) {
      console.error(apiResponse?.exception);
      return;
    }
    let userFavorites: Favorite[] = apiResponse.payload;
    this.favoriteId = userFavorites.find((favorite) => {
      return favorite.product.productId === this.productId;
    })?.favoriteId;
  }

  initializeSizes(){
    this.productSizes = this.product.product_sizes.data;
  }

  assignFavoriteId(favoriteId: number) {
    this.favoriteId = favoriteId;
  }

  openLoginModal() {
    this.navbarService.setStatusLogin(true);
  }

  convertMdToHtml(markdown: string): string {
    return new showdown.Converter().makeHtml(markdown);
  }

  changeProductVariation(productSize: CmsObjectData<ProductSize>) {
    const productVariation = this.product.variations.data.find(variantion => variantion.attributes.product_size.data.id === productSize.id);
    this.addProductForm.patchValue({ selectedVariation: productVariation });
  }

  onQuantityChange(newQuantity: number) {
    this.addProductForm.patchValue({ quantity: newQuantity });
  }

  changeBuyOption(option: string) {
    if (option === 'once') {
      this.addProductForm.get('recurrence')?.disable();
    } else {
      this.addProductForm.get('recurrence')?.enable();
    }
    this.addProductForm.patchValue({ buyOption: option });
  }

  changeShowcaseImg(img: CmsObjectData<CmsMedia>) {
    this.currentShowcaseImg = this.cmsHelper.getUrlFromMediaData(
      img.attributes
    );
  }

  async addToCart() {
    if (this.product.is_customized && !this.isQuizResult) {
      await this.router.navigate(['/uat/quiz']);
    } else {
      const productVariation = this.addProductForm.get("selectedVariation")?.value;
      if(productVariation){
        await this.cartService.addProductVariation(productVariation, this.addProductForm.value.quantity, this.product.attributes);
        this.navbarService.setStatusCart(true);
      }
    }
  }

  async removeFromCart() {
    await this.cartService.removeItemByProductId(this.productId);
  }

  setupCurrentVariation(){
    const variationId = this.product.currentProductVariationId;
    let currentProductVariation;
    if(variationId){
      currentProductVariation = this.product.variations.data.find(p => p.attributes.shopify_variation_id === String(variationId))
      if(currentProductVariation) this.changeVariation(currentProductVariation);
      return;
    }

    currentProductVariation = this.cmsHelper.getMainVariation(this.product);
    this.changeVariation(currentProductVariation);
  }

  changeVariation(variation: CmsObjectData<ProductVariation>) {
    this.addProductForm.patchValue({ selectedVariation: variation });
  }
}
