import { CmsMedia } from '../models/cms/CmsMedia';
import { CmsObjectSingleType } from '../models/cms/CmsObjectSingleType';
import { environment } from '../../../environments/environment';
import { CmsObjectCollectionType } from '../models/cms/CmsObjectCollectionType';
import { Injectable } from '@angular/core';
import { Product } from '../models/Product';
import { CmsObjectData } from '../models/cms/CmsObejctData';
import { ProductVariation } from '../models/ProductVariation';
import { CmsFormats } from '../models/cms/CmsFormats';

@Injectable({
  providedIn: 'root',
})
export class CmsHelper {
  getUrlFromMedia(
    cmsMediaObject: CmsObjectSingleType<CmsMedia>,
    format?: keyof CmsFormats
  ): string {
    return this.getUrlFromMediaData(cmsMediaObject?.data?.attributes, format);
  }

  getUrlFromMediaData(
    mediaDataObject: CmsMedia,
    format?: keyof CmsFormats
  ): string {
    let url;

    if (format) {
      if (!mediaDataObject?.formats) {
        throw new Error('formats at mediaDataObject is null or undefined');
      }
      url = mediaDataObject?.formats[format]?.url ?? mediaDataObject?.url;
    } else {
      url = mediaDataObject?.url;
    }
    return url;
  }

  /**
   * Returns the image url from the collection of medias within the index provided
   *
   * @param cmsCollectionMedias the collection of medias
   * @param index the index of the image to return
   * @returns the image url
   */
  getUrlFromCollectionMedias(
    cmsCollectionMedias: CmsObjectCollectionType<CmsMedia>,
    index: number
  ): string {
    return this.getUrlFromMediaData(cmsCollectionMedias.data[index].attributes);
  }

  /**
   * Returns the variation flaged as main for a product
   * If no variation is flagged as main, the first variation is returned
   */
  getMainVariation(product: Product): CmsObjectData<ProductVariation> {
    let main = product.variations.data.find(
      (variations) => variations.attributes.is_main
    );

    if (!main) {
      main = product.variations.data[0];
    }

    return main;
  }

  formatStringWithPlaceholder(
    input: string,
    replacements: { [key: string]: string }
  ) {
    const regex = /\{(.*?)\}/g;

    const transformedString = input.replace(regex, (match, placeholder) => {
      if (replacements.hasOwnProperty(placeholder)) {
        return replacements[placeholder];
      }

      return match;
    });

    return transformedString;
  }
}
