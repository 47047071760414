import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private openCartStatus = new BehaviorSubject<boolean>(false);
  private openLoginStatus = new BehaviorSubject<boolean>(false);

  constructor() {}

  setStatusCart(status: boolean) {
    this.openCartStatus.next(status);
  }

  getIsOpenCart() {
    return this.openCartStatus.asObservable();
  }

  setStatusLogin(status: boolean) {
    this.openLoginStatus.next(status);
  }

  getIsOpenLogin() {
    return this.openLoginStatus.asObservable();
  }
}
