<div class="dialog">
  <div #signUpElement class="section sing-up">
    <h2>Sign up</h2>
    <form [formGroup]="subscriptionForm" enctype="multipart/form-data">
      <div class="sing-up__row">
        <form-input [inputId]="'signUpFirstName'" [type]="'text'" [name]="'firstName'" [placeholder]="'First Name *'"
          [fieldControl]="subscriptionFormControls.firstName" [errors]="[
        {
          name: 'required',
          message: 'Please enter a first name'
        },
        {
          name: 'minlength',
          message: 'First name must be between 2 and 60 characters long'
        },
        {
          name: 'maxlength',
          message: 'First name must be between 2 and 60 characters long'
        },]"></form-input>
      </div>
      <div class="sing-up__row">
        <form-input [inputId]="'signUpLastName'" [type]="'text'" [name]="'lastName'" [placeholder]="'Last Name'"
          [fieldControl]="subscriptionFormControls.lastName" [errors]="[
          {
            name: 'minlength',
            message: 'Last name must be between 2 and 60 characters long'
          },
          {
            name: 'maxlength',
            message: 'Last name must be between 2 and 60 characters long'
          },]"></form-input>
      </div>
      <div class="sing-up__row">
        <form-input [inputId]="'signUpEmail'" [type]="'email'" [name]="'email'" [placeholder]="'Email address *'"
          [fieldControl]="subscriptionFormControls.email" [errors]="[
      {
        name: 'required',
        message: 'Email is required'
      },
      {
        name: 'pattern',
        message: 'Email must be a valid email address'
      }]"></form-input>
      </div>

      <div class="sing-up__row">
        <form-input-password [inputId]="'signInPassword'" [name]="'password'" [placeholder]="'Password *'"
          [fieldControl]="subscriptionFormControls.password" [errors]="[
      {
        name: 'required',
        message: 'Password is required'
      },
      {
        name: 'pattern',
        message: 'Password must be at least one letter, one number and one special character'
      },
      {
        name: 'minlength',
        message: 'Password must be at least 8 characters'
      },
      ]" [isVisiblePassword]="isVisiblePassword" (onChangePasswordView)="changePasswordView()"></form-input-password>
      </div>

      <div class="sing-up__row">
        <form-input-password [inputId]="'signInConfirmPassword'" [name]="'confirmPassword'"
          [placeholder]="'Confirm Password *'" [fieldControl]="subscriptionFormControls.confirmPassword" [errors]="[
      {
        name: 'required',
        message: 'Confirm Password is required'
      },
      {
        name: 'notSame',
        message: 'Password and confirm not match'
      },
      ]" [isVisiblePassword]="isVisibleConfirmPassword"
          (onChangePasswordView)="changeConfirmPasswordView()"></form-input-password>
      </div>
      <div>

      </div>

      <div class="checkbox-container">
        <label> What's your gender? </label>
        <div class="checkbox-container__wrap">
          <form-checkbox (onClick)="changeGender('female')" [inputId]="'checkbox-allow-contact-female'"
            [text]="'Female'" [fieldControl]="genresControls.female"></form-checkbox>
          <form-checkbox (onClick)="changeGender('male')" [inputId]="'checkbox-allow-contact-male'"
            [text]="'Male'" [fieldControl]="genresControls.male"></form-checkbox>
          <form-checkbox (onClick)="changeGender('nonBinary')" [inputId]="'checkbox-allow-contact-nb'"
            [text]="'Non-binary'" [fieldControl]="genresControls.nonBinary"></form-checkbox>
          <form-checkbox (onClick)="changeGender('other')" [inputId]="'checkbox-allow-contact-other'"
            [text]="'Other'" [fieldControl]="genresControls.other"></form-checkbox>
          <form-checkbox (onClick)="changeGender('preferNotToSay')"
            [inputId]="'checkbox-allow-contact-preferNotToSay'" [text]="'Prefer not to say'"
            [fieldControl]="genresControls.preferNotToSay"></form-checkbox>
        </div>
      </div>
      <div class="birth-date">
        <label> What's your date of birth? </label>
        <form-date-picker [max]="currentDate" [errors]=" [{
        name: 'required',
        message: 'Date of birth is required'
      },
      {
        name: 'lessThanToday',
        message: 'It is not possible to add a future date'
      },
      {
        name: 'veryOldDate',
        message: 'it is not possible to add a very old date'
      }]" [placeholder]="'Enter your birthday *'" [fieldControl]="subscriptionFormControls.birthday"></form-date-picker>
      </div>
      <label class="sign-in-footer">By continuing, you agree to Curl Lab's
        <a (click)="closeModal()" [routerLink]="['/uat/policy-terms']">Terms of Use and Privacy Policy</a></label>

      <div class="create-account">
        <form-button [disabled]="subscriptionForm.invalid" (onClick)="onSubmit()" [isLoading]="isLoading" type="'submit'" [text]="'Create Account'"
          [varient]="buttonTypes.contained"></form-button>
        <div class="w-100 d-flex justify-content-center mb-3">
          <feedback-text [fontWeight]="700" [varient]="feedback.varient" [hidden]="!feedback.hasFeedback"
            [text]="feedback.msg"> </feedback-text>
        </div>
      </div>
      <div class="sing-in">
        <label>Already have an account?</label>
        <a (click)="changeSignInView()">SIGN IN</a>
      </div>
    </form>
  </div>
</div>
