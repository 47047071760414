import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getCurrentDate(){
    return new Date();
  }

  getOldDateLimit(){
    return new Date(1899,12,31);
  }

  formatToISOString(date: Date){
    return date.toISOString().split('T')[0]
  }
}
