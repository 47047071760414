import { Component, Input, OnInit } from '@angular/core';
import { NavbarService } from 'src/app/public/services/navbar.service';
import { CmsHelper } from '../../common/CmsHelper';
import { Product } from '../../models/Product';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-bought-together',
  templateUrl: './bought-together.component.html',
  styleUrls: ['./bought-together.component.scss'],
})
export class BoughtTogetherComponent implements OnInit {
  @Input() title = 'OTHERS ALSO BOUGHT';
  @Input() products!: Product[];
  @Input() bought_together!: Product['bought_together'];

  public prods: {
    description: string;
    category: string;
    product: Product;
    volume: string;
    price: number;
  }[] = [];

  constructor(
    public cmsHelper: CmsHelper,
    private navbarService: NavbarService,
    private cartService: CartService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.products && this.bought_together) {
      throw new Error('You should either pass products or bought_together');
    }

    if (!this.products) {
      this.prods = await Promise.all(
        this.bought_together.map(
          async ({
            custom_category,
            custom_description,
            product: {
              data: { attributes },
            },
          }) => {
            const mainVariation = this.cmsHelper.getMainVariation(attributes);

            return {
              category: custom_category,
              description: custom_description,
              product: attributes,
              volume: mainVariation.attributes.volume,
              price: mainVariation.attributes.price,
            };
          }
        )
      );
    } else if (!this.bought_together) {
      this.prods = await Promise.all(
        this.products.map(async (product) => {
          const mainVariation = this.cmsHelper.getMainVariation(product);

          return {
            product,
            category: product.categories.data[0].attributes.name,
            description: product.description,
            volume: mainVariation.attributes.volume,
            price: mainVariation.attributes.price,
          };
        })
      );
    }
  }

  async addToCart(product: Product) {
    await this.cartService.addProduct(product);
    this.navbarService.setStatusCart(true);
  }
}
