import { Component, OnInit, OnChanges, Output, Input, ViewChild, EventEmitter, ElementRef  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() openDialog: boolean = false;
  @ViewChild('modal', { static: true }) modalRef!: ElementRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {}

  ngOnChanges() {
    if(this.openDialog) {
     this.openModal(this.modalRef);
    } else {
     this.closeModal();
    }
  }

  openModal(content: any){
    this.modalService.open(content, { centered: true, }).result.then((result)=>{
      this.onClose.emit();
    },
    (reason)=>{
      this.onClose.emit();
    });
  }
  closeModal(){
    this.modalService.dismissAll();
  }

}
