<nav #navbar [ngClass]="isMenuOpen && !isLoginOpen && !isCartOpen ? 'active' : ''">
  <a [routerLink]="['/uat']">
    <img
      [src]="curllabLogo"
      alt="Curl lab"
      width="155"
      height="46"
    />
  </a>
  <div id="btn-mobile" (click)="handleClickHamburger()">
    <span id="hamburger"></span>
  </div>
  <ul id="menu">
    <li class="links" [ngClass]="{ selected: path.endsWith('uat') }">
      <a [routerLink]="['/uat']">Home</a>
    </li>
    <li class="links" [ngClass]="{ selected: path.includes('products') }">
      <a [routerLink]="['/uat/products']">Shop</a>
    </li>
    <li class="links" [ngClass]="{ selected: path.includes('reviews') }">
      <a [routerLink]="['/uat/reviews']">Reviews</a>
    </li>
    <li class="links" [ngClass]="{ selected: path.includes('into-the-lab') }">
      <a [routerLink]="['/uat/into-the-lab']">Into the Lab</a>
    </li>
    <li class="links" [ngClass]="{ selected: path.includes('curl-knowledge') }">
      <a [routerLink]="['/uat/curl-knowledge']">Curl Knowledge</a>
    </li>
    <li class="links">
      <button type="button" class="default-button hair-consultation-button" (click)="goToQuiz()">
        HAIR CONSULTATION
      </button>
    </li>
    <div id="icons">
      <li id="cart" (click)="handleClickCart()">
        <img [src]="cartIcon" alt="cart" class="cart" />
      </li>
      <li class="profile" (click)="handleClickProfileNotLoggedIn()" *ngIf="!isLogged">
        <img [src]="profileNotLoggedIcon" alt="profile" class="img-profile" />
      </li>
      <li class="profile" [routerLink]="['/uat/account']" *ngIf="isLogged">
        <img [src]="profileLoggedIcon" alt="account" class="img-profile" />
      </li>
    </div>
  </ul>
</nav>
<app-cart *ngIf="isCartOpen" (close)="closeCart()"></app-cart>
