import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Attributes } from 'src/app/shared/models/ItemInCart';
import { ShopifyCart } from 'src/app/shared/models/shopify/ShopifyCart';
import { ShopifyService } from './shopify.service';
import { Product } from 'src/app/shared/models/Product';
import { ShopifyCartLine, ShopifyCartLineUpdate, ShopifyUpdateCartItem } from 'src/app/shared/models/shopify/ShopifyCartLineUpdate';
import { UserService } from './user.service';
import { ShopifyItem } from 'src/app/shared/models/shopify/ShopifyLine';
import { ProductVariation } from 'src/app/shared/models/ProductVariation';
import { CmsObjectData } from 'src/app/shared/models/cms/CmsObejctData';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  currentCart = new BehaviorSubject<ShopifyCart | undefined>(undefined);

  constructor(private shopifyService: ShopifyService, private userService: UserService) {}

  getCurrentCart(){
    return this.currentCart.asObservable();
  }

  setCurrentCart(newCart: ShopifyCart | undefined){
    this.currentCart.next(newCart);
  }

 async updateCart(cart: ShopifyCart | undefined = undefined) {
    cart = cart ? cart : await this.getCartFromApi();
    if(cart)  {
        this.setCurrentCart(cart);
        this.setCartCache(cart);
    }
  }

  public async addProduct(product: Product, quantity: number = 1, attributes?: Attributes[]){
    const shopifyId = product.variations.data.find(p => p.attributes.is_main)?.attributes.shopify_variation_id;
    if(shopifyId) await this.addToCart(shopifyId, quantity, attributes);
  }

  public async addProductVariation(productVariation: CmsObjectData<ProductVariation>, quantity: number = 1, attributes?: Attributes[]){
    const shopifyId = productVariation.attributes.shopify_variation_id;
    await this.addToCart(shopifyId, quantity, attributes);
  }

  private async addToCart(shopifyId: string, quantity: number = 1, attributes: Attributes[] = []) {
    const formattedShopifyId = this.getFormattedShopifyId(shopifyId);
    const cartLine: ShopifyCartLine  = {
      variantId: formattedShopifyId,
      attributes,
      quantity
    }
    let cartId = this.getCartIdFromStorage();
    let cartLineUpdate: ShopifyCartLineUpdate = {
      cartId: cartId,
      cartLines: [cartLine]
    };
    let addProductCall = this.shopifyService.addCartLine(cartLineUpdate);
    let addProductResponse = await lastValueFrom(addProductCall);
    let cartResult: ShopifyCart = addProductResponse.payload;
    this.setCurrentCart(cartResult);
    this.setCartCache(cartResult);
  }

  public async addAllToCart(cartLines: ShopifyCartLine[]){
    cartLines.forEach(line =>{
      line.variantId = this.getFormattedShopifyId(line.variantId);
    })

    let cartId = this.getCartIdFromStorage();
    let cartLineUpdate: ShopifyCartLineUpdate = {
      cartId: cartId,
      cartLines: cartLines
    };
    let addProductCall = this.shopifyService.addCartLine(cartLineUpdate);
    let addProductResponse = await lastValueFrom(addProductCall);
    let cartResult: ShopifyCart = addProductResponse.payload;
    this.setCurrentCart(cartResult);
    this.setCartCache(cartResult);
  }

  setCartCache(cart: ShopifyCart){
      if(cart){
        localStorage.setItem("cartId", cart.id);
      }
  }

  getCartIdFromStorage(): undefined | string{
    const cartId = localStorage.getItem('cartId');
    if (!cartId) return;
    return cartId;
  }

  public async getCartFromApi() {
    let cartId = this.getCartIdFromStorage();
    let getCartCall = this.shopifyService.getCart(cartId);
    let getCartResponse = await lastValueFrom(getCartCall);
    let cart: ShopifyCart = getCartResponse.payload;
    return cart;
  }

  async getCheckoutUrl(): Promise<string> {
    const currentCart = this.currentCart.getValue();

    if(!currentCart){
      throw new Error('Cart not found');
    }

    let checkoutUrlCall = this.shopifyService.getCheckoutUrl(currentCart.id);
    let checkoutUrlResponse = await lastValueFrom(checkoutUrlCall);
    return checkoutUrlResponse.payload;
  }

  async updateCartIfExist(){
    const currentCart = this.currentCart.getValue();
    if(currentCart){
      await this.updateCart();
    }
  }

  async updateCartStates(){
    const cartId = this.getCartIdFromStorage();
    const currentCart = this.currentCart.getValue();
    if(!currentCart && cartId){
      await this.updateCart();
    }
  }

  public checkProductInCart(productVariation: CmsObjectData<ProductVariation>) {
    const items = this.currentCart.value?.items;
    return (
      items || []
    ).some((cartItem) => cartItem.merchandise.variantId === this.getFormattedShopifyId(productVariation.attributes.shopify_variation_id));
  }

  public async removeItem(item: ShopifyItem){
    await this.updateItemQuantity(item, 0);
  }

  public async updateItemQuantity(item: ShopifyItem, quantity: number){
    const updateCartItem: ShopifyUpdateCartItem[] = [{
      lineId: item.id,
      quantity
    }]

    let cartId = this.getCartIdFromStorage();

    let addProductCall = this.shopifyService.updateCartItem(updateCartItem, cartId);
    let addProductResponse = await lastValueFrom(addProductCall);
    let cart: ShopifyCart = addProductResponse.payload;

    this.setCurrentCart(cart);
    this.setCartCache(cart);
  }

  public async removeItemByProductId(productId: number){
    const cart = this.currentCart.value;
    const shopifyItem = cart?.items?.find(item => item.merchandise.productId === String(productId));
    if(shopifyItem){
      await this.removeItem(shopifyItem);
    }
  }

  public clearCart(){
    this.setCurrentCart(undefined);
  }

  public getFormattedShopifyId(shopifyId: string){
    return "gid://shopify/ProductVariant/" + shopifyId;
  }
}
