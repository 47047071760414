import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TagManagerService {

  create(id: string) {
    const head = document.querySelector('head') as HTMLHeadElement;
    const body = document.querySelector('body') as HTMLBodyElement;

    const tagManagerElement = this.createScriptElement(this.getScript(id));
    const noScriptElement = this.getNoScriptElemenet(id);

    head.insertAdjacentElement('afterbegin', tagManagerElement);
    tagManagerElement.insertAdjacentHTML('beforebegin', '<!-- Google Tag Manager -->');
    tagManagerElement.insertAdjacentHTML('afterend', '<!-- End Google Tag Manager -->');

    body.insertAdjacentElement('afterbegin', noScriptElement);
    noScriptElement.insertAdjacentHTML('beforebegin', '<!-- Google Tag Manager (noscript) -->');
    noScriptElement.insertAdjacentHTML('afterend', '<!-- End Google Tag Manager (noscript) -->');
  }

  private createScriptElement(script: string) {
    const scriptElement = document.createElement('script');

    scriptElement.innerHTML = script;
    return scriptElement;
  }

  private getScript(id: string): string {
    return `(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${id}');`;
  }

  private getNoScriptElemenet(id: string) {
    const noScriptElement = document.createElement('noscript');

    noScriptElement.insertAdjacentHTML(
      'afterbegin',
      ` <iframe
        src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0"
        width="0"
        style="display: none; visibility: hidden">
      </iframe> `
    );

    return noScriptElement;
  }
}
