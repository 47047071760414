import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EButtonVarient } from '../../models/Button';

@Component({
  selector: 'form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.scss']
})
export class FormButtonComponent implements OnInit {

  @Input() text = '';
  @Input() type = 'button';
  @Input() isLoading = false;
  @Input() disabled = false;
  @Input() varient: EButtonVarient = EButtonVarient.contained;
  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleButtonClick(event: Event){
    this.onClick.emit(event);
  }

  getButtonVarientClass(){
    if (this.disabled) {
      return 'form-button--disabled'
    }
    switch(this.varient){
      case EButtonVarient.contained:
        return 'form-button--contained'
      case EButtonVarient.outlined:
        return 'form-button--outlined'
      case EButtonVarient.darkContained:
        return 'form-button--dark-contained'
      default:
        return 'form-button--contained'
    }
  }
}
