import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { ItemInCart } from '../../models/ItemInCart';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/public/services/navbar.service';
import { ShopifyCart } from '../../models/shopify/ShopifyCart';
import { CartService } from 'src/app/core/services/cart.service';
import { ShopifyItem } from '../../models/shopify/ShopifyLine';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public cartItems: Array<ItemInCart> = [];
  public currentCart?: ShopifyCart;
  public subtotal = 0;
  public cart?: ShopifyCart;
  public isLoading = true;
  public currentCartSubscription = new Subscription();
  private timeoutId: any;
  private isRemoveLoading = false;

  constructor(
    private navbarService: NavbarService,
    private changeDetector: ChangeDetectorRef,
    private cartService: CartService
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentCartSubscription = this.cartService.getCurrentCart().subscribe(currentCart => {
      if(currentCart){
        this.isLoading = false;
        this.currentCart = currentCart;
        this.changeDetector.markForCheck();
      }
    })
  }

  ngOnDestroy(): void {
    this.currentCartSubscription.unsubscribe();
  }

  async onQuantityChange(item: ShopifyItem, quantity: number){
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async ()=>{
      await this.cartService.updateItemQuantity(item, quantity);
    }, 700);
  }

  async removeItem(item: ShopifyItem){
  if(!this.isRemoveLoading){
    this.isRemoveLoading = true;
    await this.cartService.removeItem(item);
    this.isRemoveLoading = false;
  }
  }

  closeCart() {
    this.close.emit();
    this.navbarService.setStatusCart(false);
  }

  async goToCheckout() {
    const checkoutUrl = await this.cartService.getCheckoutUrl();
    if(checkoutUrl){
      window.location.href = checkoutUrl;
    }
  }
}
