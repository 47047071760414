import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoginService } from 'src/app/core/services/login.service';
import { FavoriteService } from 'src/app/private/services/favorite.service';
import { Favorite } from '../../models/Favorite';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteComponent implements OnInit {
  @Input() favoriteId!: number | undefined;
  @Input() productId!: number;

  @Output() onAdd: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() userUnauthenticated: EventEmitter<any> = new EventEmitter();


  constructor(private favoriteService: FavoriteService, private loginService: LoginService) { }

  ngOnInit(): void {  }

  async updateFavorite() {
    if (!this.isUserLoggedIn()) {
      this.userUnauthenticated.emit();
      return;
    }
    if (this.favoriteId) {
      this.removeFromFavorite();
    } else {
      this.addToFavorite();
    }
  }

  isUserLoggedIn(): boolean {
    if (this.loginService.getSession()) {
      return true;
    }
    return false;
  }

  async addToFavorite() {
    if (this.isAbleToFavorite()) {
      const addToFavoriteCall = this.favoriteService.addToFavorite(this.productId);
      const addToFavoriteResponse = await lastValueFrom(addToFavoriteCall);
      if (addToFavoriteResponse.exception) {
        console.error(addToFavoriteResponse.exception);
        return;
      }
      this.onAdd.emit(addToFavoriteResponse.payload.favoriteId);
    }
  }

  async removeFromFavorite() {
    if (!this.favoriteId) {
      console.error("It's not possible to remove a favorite without a favorite");
      return;
    }
    const removeFromFavoriteCall = this.favoriteService.removeFromFavorite(this.favoriteId);
    const removeFromFavoriteResponse = await lastValueFrom(removeFromFavoriteCall);
    if (removeFromFavoriteResponse.exception) {
      console.error(removeFromFavoriteResponse.exception);
      return;
    }
    this.onRemove.emit(this.favoriteId);
  }

  private isAbleToFavorite(): boolean {
    if (!this.loginService.isLoggedIn()) {
      return false
    }
    if (!this.productId) {
      console.error("Something went wrong, it's not possible to add a favorite without a product id");
      return false;
    }
    return true
  }
}
