<div class="image-uploader" [style.flex-direction]="previewImage ? 'column-reverse' : 'column'">
  <label for="upload">
    <div *ngIf="!previewImage; else textBlock" class="image-uploader__icon">
      <img src="assets/images/upload.png" alt="Upload Icon">
    </div>

    <ng-template #textBlock>
      <p>Change photo</p>
    </ng-template>
  </label>
  <input id="upload" type="file" accept="image/*" (change)="onImageChange($event)">

  <div *ngIf="previewImage" class="image-uploader__preview">
    <img [src]="previewImage" alt="Upload Icon">
  </div>
</div>