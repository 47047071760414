<div class="dialog">
  <div class="recover-password-email" *ngIf="!emailSent">
    <h2>Reset Password</h2>
    <label>Enter your account email address, and we’ll send you a link to reset
      your password.</label>
    <form [formGroup]="startRecoverPasswordForm" (ngSubmit)="onSubmit()">
      <form-input [inputId]="'recoverPasswordEmail'" [type]="'email'" [name]="'email'" [placeholder]="'Email address'"
        [fieldControl]="startRecoverPasswordFormControls.email" [errors]="[
        {
          name: 'required',
          message: 'Email is required'
        },
        {
          name: 'pattern',
          message: 'Email must be a valid email address'
        }
      ]"></form-input>
      <form-button [disabled]="startRecoverPasswordForm.invalid" [isLoading]="isLoading" type="'submit'" [text]="'Send Email'"></form-button>
    </form>
  </div>
  <div class="recover-password-email-sent" *ngIf="emailSent">
    <h2>Reset Password</h2>
    <label>We have sent an email to {{ email }} with a link to reset your
      password (it might take a couple of minutes). If you still can't find
      it, check your junk mail!
    </label>
    <button class="sign-in" (click)="closeRecoverPassword()">Close</button>
  </div>
  <label class="sign-in-footer">By continuing, you agree to Curl Lab's
    <a (click)="closeModal()" [routerLink]="['/uat/policy-terms']">Terms of Use and Privacy Policy</a></label>
    <div class="sing-in">
      <label>Go back to sign in?</label>
      <a (click)="changeSignInView()">SIGN IN</a>
    </div>
</div>
