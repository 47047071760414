<div class="section-1__product">
  <div class="section-1__product__image">
    <img [src]="currentShowcaseImg" alt="product image" />
    <h1 class="section-1__product__details__name hide-desktop">
      {{ product.name }}
    </h1>
  </div>
  <form class="section-1__product__details" [formGroup]="addProductForm">
    <ng-container *ngIf="!isQuizResult"
      ><div class="section-1__product__details__categories">
        <span *ngFor="let category of product.categories.data">
          {{ category.attributes.name }}
        </span>
      </div>
      <div class="section-1__product__details__guides">
        <div class="section-1__product__details__guides__reviews">
          <ngb-rating
            class="rating"
            [(rate)]="rate"
            [max]="max"
            [readonly]="true"
          >
          </ngb-rating>
          <span class="section-1__product__details__guides__reviews__group">
            {{ this.totalApprovedReviews }} Reviews
          </span>
        </div>
        <app-favorite
          [favoriteId]="favoriteId"
          [productId]="productId"
          (onAdd)="assignFavoriteId($event)"
          (onRemove)="favoriteId = undefined"
          (userUnauthenticated)="openLoginModal()"
        ></app-favorite></div
    ></ng-container>

    <h1 class="section-1__product__details__name hide-mobile">
      {{ product.name }}
    </h1>
    <ng-container *ngIf="!isQuizResult; else headerIsQuizResult">
      <div
        class="section-1__product__details__description"
        [innerHTML]="convertMdToHtml(product.description)"
      >
        {{ product.description }}
      </div>
    </ng-container>

    <ng-template #headerIsQuizResult>
      <div class="section-1__product__details_pro_tip">
        <p><span>PRO TIP:</span> {{ productProTip }}</p>
      </div>
    </ng-template>
    <div class="section-1__product__details__sizes" *ngIf="productSizes.length > 0">
      <span class="describer">Sizes</span>
      <div class="elements">
        <span
          *ngFor="let size of productSizes"
          (click)="changeProductVariation(size)"
          class="section-1__product__details__sizes__size"
          [ngClass]="{
            'section-1__product__details__sizes__size--selected':
            size.id === form['selectedVariation'].value?.attributes.product_size.data.id
          }"
        >
          {{ size.attributes.volume }}
        </span>
      </div>
    </div>
    <div class="section-1__product__details__quantity">
      <span class="describer">Quantity</span>
      <div class="elements">
        <app-quantity-input
          class="section-1__product__details__quantity__qtd-input"
          [currentQuantity]="form['quantity'].value"
          (quantityChange)="onQuantityChange($event)"
        >
        </app-quantity-input>
        <select
          class="section-1__product__details__quantity__recurrence"
          [disabled]="form['recurrence'].disabled ? true : false"
        >
          <option
            *ngFor="let recurrence of recurrences"
            [value]="recurrence.code"
          >
            {{ recurrence.description }}
          </option>
        </select>
      </div>
    </div>
    <div class="section-1__product__details__options">
      <div class="section-1__product__details__options__option">
        <div class="section-1__product__details__options__option__informations">
          <input type="radio" value="subscribe" formControlName="buyOption" />
          <label (click)="changeBuyOption('subscribe')" for="buyOption">
            Subscribe & save XX%
          </label>
        </div>
        <span class="price-label">$xxx</span>
      </div>
      <div class="section-1__product__details__options__option">
        <div class="section-1__product__details__options__option__informations">
          <input type="radio" value="once" formControlName="buyOption" />
          <label (click)="changeBuyOption('once')" for="buyOption">
            Buy once
          </label>
        </div>
        <span class="price-label">
          ${{ form["selectedVariation"].value?.attributes?.price }}
        </span>
      </div>
    </div>
    <div class="section-1__product__details__button__quiz">
      <button
        class="default-button"
        *ngIf="!isAddedToCart; else isAdded"
        (click)="addToCart()"
      >
      {{ isQuizResult ? "Add to cart" : product.is_customized ? 'Get My Own' : 'Quick Add' }}
      </button>
      <ng-template #isAdded>
        <button
          class="default-button remove-from-cart"
          (click)="removeFromCart()"
        >
          <img src="assets/svg/check-green.svg" /> Added to cart
        </button>
        <span class="remove-product" (click)="removeFromCart()">Remove</span>
      </ng-template>
    </div>
  </form>
</div>
<div class="section-1__image-changer" *ngIf="!isQuizResult">
  <img
    *ngFor="let image of product.images.data"
    [attr.src]="cmsHelper.getUrlFromMediaData(image.attributes)"
    (click)="changeShowcaseImg(image)"
  />
</div>
