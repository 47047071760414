import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UnsubscribeUserService } from '../../../core/services/unsubscribe-user.service';
import { lastValueFrom } from 'rxjs';
import { ResponseModel } from '../../models/Response';

@Component({
  selector: 'app-unsubscribe-user',
  templateUrl: './unsubscribe-user.component.html',
  styleUrls: ['./unsubscribe-user.component.scss'],
})
export class UnsubscribeUserComponent {
  @Input() unsubscribeCode: string = '';

  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  public isLoading = false;
  public successUnsubscribe = false;
  public invalidUnsubscribe = false;

  constructor(private unsubscribeUserService: UnsubscribeUserService) {}

  onClose(): void {
    this.close.emit();
  }

  async onSubmit() {
    this.isLoading = true;

    const subscription = this.unsubscribeUserService.unsubscribeUser(
      this.unsubscribeCode
    );
    const response: ResponseModel<string> = await lastValueFrom(subscription);

    if (response.payload) {
      this.isLoading = false;
      this.successUnsubscribe = true;
    } else {
      this.isLoading = false;
      this.invalidUnsubscribe = true;
    }

    return response;
  }
}
