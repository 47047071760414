import {
  ChangeDetectionStrategy, Component, Input, OnInit
} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  @Input() hideLinks: boolean = false;

  public curllabLogoImg: string = '/assets/svg/logo-light.svg';
  public instagramLogoImg: string = '/assets/svg/instagram-logo-light.svg';
  public facebookLogoImg: string = '/assets/svg/facebook-logo-light.svg';
  public twitterLogoImg: string = '/assets/svg/twitter-logo-light.svg';
  public pinterestLogoImg: string = '/assets/svg/pinterest-logo-light.svg';
  public youtubeLogoImg: string = '/assets/svg/youtube-logo-light.svg';
  public linkedinLogoImg: string = '/assets/svg/linkedin-logo-light.svg';
  public tiktokLogoImg: string = '/assets/svg/tiktok-logo-light.svg';

  constructor() { }

  ngOnInit(): void { }

  scrollTo(el: HTMLElement): void {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
