export interface IFeedback {
  hasFeedback: boolean;
  msg: string;
  varient: 'error' | 'success';
}

export class Feedback implements IFeedback {
  hasFeedback: boolean;
  msg: string;
  varient: "error" | "success";

  constructor(){
    this.hasFeedback = false;
    this.msg = '';
    this.varient = 'error';
  }

  setFeedback(msg: string, varient: IFeedback["varient"] = 'error' ) {
    this.msg = msg;
    this.varient = varient;
    this.hasFeedback = true;
  }
}
