import {
  AfterContentInit,
  Directive,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: 'p[appRouterLinkText]',
})
export class RouterLinkTextDirective implements AfterContentInit {
  constructor(
    private el: ElementRef<HTMLParagraphElement>,
    private renderer2: Renderer2,
    private router: Router
  ) {}

  ngAfterContentInit(): void {
    const newParagraph = this.getLinks(this.el.nativeElement.innerHTML);

    const parent = this.el.nativeElement.parentElement;
    this.renderer2.removeChild(parent, this.el.nativeElement);
    this.renderer2.appendChild(parent, newParagraph);
  }

  getLinks(innerText: string) {
    const newParagraph = this.renderer2.createElement('p');

    Array.from(innerText.matchAll(/(.*?)\((.*?)\)\[(.*?)\]/gs)).forEach(
      ([, beforeText, linkText, url]) => {
        const textValue = this.renderer2.createText(beforeText);
        this.renderer2.appendChild(newParagraph, textValue);

        const anchor = this.renderer2.createElement('a') as HTMLAnchorElement;
        anchor.innerText = linkText;
        this.renderer2.setAttribute(anchor, 'href', url);

        if (/^http/.test(url)) {
          this.renderer2.setAttribute(anchor, 'target', '_blank');
        } else {
          this.renderer2.listen(anchor, 'click', this.onClick(url));
        }

        this.renderer2.appendChild(newParagraph, anchor);
      }
    );

    const textAfterLastLink = innerText.replace(/^.*\(.*\)\[.*\](.*)$/gs, '$1');

    const textValue = this.renderer2.createText(textAfterLastLink);
    this.renderer2.appendChild(newParagraph, textValue);

    return newParagraph;
  }

  onClick(url: string) {
    return (event: Event) => {
      const id = url.replace(/.*?#(.*)/, '$1');
      const link = url.replace(/(.*?)#.*/, '$1') ?? url;

      event.preventDefault();
      event.stopPropagation();

      this.router.navigate([link], { queryParams: { id } });
    };
  }
}
