import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IInputError } from '../../models/Input';

@Component({
  selector: 'form-input-password',
  templateUrl: './form-input-password.component.html',
  styleUrls: ['./form-input-password.component.scss']
})
export class FormInputPasswordComponent implements OnInit {

  @Input() inputId = '';
  @Input() email = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() fieldControl!: FormControl;
  @Input() isVisiblePassword = false;
  @Input() errors: IInputError[] = []
  @Output() onChangePasswordView = new EventEmitter<Event>();
  constructor() { }

  ngOnInit(): void {
  }

  handlePasswordViewClick(event: Event) {
    this.onChangePasswordView.emit(event);
  }
}
