<h3>{{ title }}</h3>
<div class="elements">
  <div class="elements__element" *ngFor="let boughtTogether of prods">
    <img
      [src]="
        cmsHelper.getUrlFromCollectionMedias(boughtTogether.product.images, 0)
      "
      alt=""
    />
    <div class="elements__element__infos">
      <h4>{{ boughtTogether.product.name }}</h4>
      <span class="elements__element__infos__description">
        {{ boughtTogether.description }}
      </span>
      <span class="elements__element__infos__volume">
        {{ boughtTogether.volume }}
      </span>
      <span class="elements__element__infos__price">
        ${{ boughtTogether.price }}
      </span>
      <button
        class="default-button-outlined"
        (click)="addToCart(boughtTogether.product)"
      >
        add to cart
      </button>
    </div>
  </div>
</div>
