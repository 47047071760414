<footer>
  <div class="column logo">
    <img [src]="curllabLogoImg" alt="Curl lab" width="155px" height="45.71px" />
  </div>

  <div class="column company">
    <div class="links" *ngIf="!hideLinks">
      <h4 [routerLink]="['/uat/faq']">FAQ</h4>
      <h4 [routerLink]="['/uat/into-the-lab']">About Us</h4>
      <h4 [routerLink]="['/uat/work-with-us']">Work With Us</h4>
    </div>
    <div class="info">
      <h3>Contact Us</h3>
      <h4>
        5 River Road, Suite 241 <br />
        Wilton, CT - United States, ZIP 06897
      </h4>
      <br>
      <h4>info@curllab.com</h4>
      <h4>0833CURLLAB</h4>
    </div>
  </div>

  <div class="column follow-us">
    <h4>Follow Us</h4>
    <div class="social-media-icons">
      <a href="https://www.instagram.com/curllab.beauty/">
        <img [src]="instagramLogoImg" alt="" />
      </a>
      <a href="https://www.facebook.com/curllab.beauty">
        <img [src]="facebookLogoImg" alt="" />
      </a>
      <a href="https://twitter.com/CurlLab">
        <img [src]="twitterLogoImg" alt="" />
      </a>
      <a href="https://br.pinterest.com/CurlLab/_saved/">
        <img [src]="pinterestLogoImg" alt="" />
      </a>
      <a href="https://www.youtube.com/channel/UCrmXbdkGBei11NvfkKIALXQ">
        <img [src]="youtubeLogoImg" alt="" />
      </a>
      <a href="https://www.linkedin.com/company/curllab/">
        <img [src]="linkedinLogoImg" alt="" />
      </a>
      <a href="https://www.tiktok.com/@curllab">
        <img [src]="tiktokLogoImg" alt="" />
      </a>
    </div>
    <p>© 2023 by Curl Lab.</p>
    <div class="links-info" *ngIf="!hideLinks">
      <a [routerLink]="['/uat/policy-terms']">
        <span>Privacy Policy and Terms of Use</span>
      </a>
      <a [routerLink]="['/uat/policy-terms']" [queryParams]="{scrollTo: 'privacyrights'}">
        <span>Don't sell my info</span>
    </a>
      <a [routerLink]="['/uat/policy-terms/cookies']">
        <span>Cookies Terms</span>
      </a>
    </div>
  </div>
</footer>
