export class Session {
    accountGuid: string;
    actions: string[];
    expiresIn: number;
    ipAddress: string;
    lastActivityDt: number;
    startDt: number;
    statusCd: string;
    token: string;
    authChannel: string;
    userAgent: string;
  
    constructor(data: {
        accountGuid: string,
        actions: string[],
        expiresIn: number,
        ipAddress: string,
        lastActivityDt: number,
        startDt: number,
        statusCd: string,
        token: string,
        authChannel: string,
        userAgent: string;
    }) {
        this.accountGuid = data.accountGuid;
        this.actions = data.actions;
        this.expiresIn = data.expiresIn;
        this.ipAddress = data.ipAddress;
        this.lastActivityDt = data.lastActivityDt;
        this.startDt = data.startDt;
        this.statusCd = data.statusCd;
        this.token = data.token;
        this.authChannel = data.authChannel;
        this.userAgent = data.userAgent;
    }
  
}
  