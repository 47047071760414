import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { ShopifyCartLineUpdate, ShopifyUpdateCartItem} from 'src/app/shared/models/shopify/ShopifyCartLineUpdate';
import {Order} from "../../public/models/Order";
import {ResponseModel} from "../../shared/models/Response";

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {
  private static URLS = {
    CART: `${environment.apiUrl}/v2/shopify/cart`,
    GET_CHECKOUT_URL: `${environment.apiUrl}/v2/shopify/cart/checkout`,
    GET_ORDERS: `${environment.apiUrl}/shopify/orders`,
  };

  constructor(private http: HttpClient) {
  }

  addCartLine(shopifyCartLineUpdate: ShopifyCartLineUpdate): Observable<any> {
    let params;
    if(shopifyCartLineUpdate.cartId){
     params = new HttpParams().set('cartId', shopifyCartLineUpdate.cartId);
    }
    return this.http.post(ShopifyService.URLS.CART, shopifyCartLineUpdate.cartLines, {params});
  }

  getCart(cartId: string | undefined): Observable<any> {
    let params;
    if(cartId){
     params = new HttpParams().set('cartId', cartId);
    }
    return this.http.get(ShopifyService.URLS.CART, {params});
  }

  getCheckoutUrl(cartId: string): Observable<any> {
    const params = new HttpParams().set('cartId', cartId);
    return this.http.get(ShopifyService.URLS.GET_CHECKOUT_URL, {params});
  }

  getOrders(email: string): Observable<ResponseModel<Order[]>> {
    const params = new HttpParams().set('email', email);
    return this.http.get<ResponseModel<Order[]>>(ShopifyService.URLS.GET_ORDERS, {params});
  }

  updateCartItem(updateCartItem: ShopifyUpdateCartItem[], cartId?: string):  Observable<any>{
    let params;
    if(cartId){
     params = new HttpParams().set('cartId', cartId);
    }
    return this.http.put(ShopifyService.URLS.CART, updateCartItem, {params});
  }
}
