import {DOCUMENT} from '@angular/common';
import {Component, ViewChild, ElementRef,Inject, OnInit, Input, AfterViewInit} from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from 'src/app/core/services/login.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {UserService} from 'src/app/core/services/user.service';
import {environment} from 'src/environments/environment';
import {LocalStorageHelper} from '../../common/Storage.helper';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { User } from '../../models/User';
import { ISignInFormFields } from '../../models/Form';
import { EButtonVarient } from '../../models/Button';
import { validateAllFormFields } from '../../utils/validateAllFormFields';
import { clearForm } from '../../utils/clearForm';
import { EMAIL_PATTERN } from '../../utils/regexPatterns';
import { Feedback } from '../../models/Feedback';
import { CartService } from 'src/app/core/services/cart.service';


declare const window: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, AfterViewInit {

  @Input() hasCreateAccountLink: boolean = true;
  @ViewChild('googleBtn', { static: true }) googleBtn!: ElementRef;

  public subscriptionForm!: FormGroup<ISignInFormFields>;
  public subscriptionFormControls!: ISignInFormFields;
  public feedback = new Feedback();
  public googleResponse: any;
  public nextPage: string | undefined = undefined;
  public isVisiblePassword: boolean = false;
  public isRecoverPassword: boolean = false;
  public isSignUp: boolean = false;
  isLoading: boolean = false;
  attempts: number = 0;
  accessToken: string = '';
  buttonTypes = EButtonVarient;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private localStorage: LocalStorageHelper,
    @Inject(DOCUMENT) private doc: Document,
    private authModalManagerService: AuthModalManagerService,
    private cartService: CartService
  ) {
    this.subscriptionForm = this.initializeForm();
    this.subscriptionFormControls = this.subscriptionForm.controls;
  }
  ngAfterViewInit(): void {
    this.buildGoogleLoginButton()
  }

  async ngOnInit() {
    this.initializeForm();
    await this.checkIfPageFromQuizResult();
  }

  initializeForm() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [Validators.required]),
      rememberLogin: new FormControl(false)
    })
  }

  async checkIfPageFromQuizResult() {
    this.route.queryParams.subscribe((params) => {
      this.nextPage = params['nextPage'];
    });
    if (!this.nextPage) return;
  }

  handleCredentialResponse(response: any) {
    let redirectTo: string = 'uat';
    this.feedback.hasFeedback = false;
    const credentials = {
      clientId: response.clientId,
      credential: response.credential,
    };
    if (this.nextPage === 'returnToQuizResults') {
      redirectTo = 'quiz/see-results';
    }
    this.userService.googleLogin(credentials).subscribe(
     async (response: any) => {
        if (response) {

          //save session in browser
          this.loginService.confirmLoginAndStore(response.payload);
          this.authModalManagerService.closeModal();
          clearForm(this.subscriptionForm);
          alert('Successfully logged in');

          if(!this.isQuizPage()){
            this.router.navigate([redirectTo], {
              queryParams: {previousPage: 'login'},
            });
          }
          await this.cartService.updateCart();
        } else {
          this.isLoading = false;
          this.notificationService.error(response.message);
          alert('Authentication Error. Please, try again!');
        }
      },
      (response) => {
        this.isLoading = false;
        this.notificationService.error('Login Failed. Please, try again!');
        throw response;
      }
    );
  }

  async onSubmit(redirectTo: string = 'uat') {
    this.feedback.hasFeedback = false;
    if(this.subscriptionForm.invalid ||  this.isLoading) {
      validateAllFormFields(this.subscriptionForm);
      return;
    };
    const credentials = {
      email: this.subscriptionForm.get('email')?.value,
      password: this.subscriptionForm.get('password')?.value,
      rememberLogin: this.subscriptionForm.get('rememberLogin')?.value,
    };
    this.isLoading = true;
    if (this.nextPage === 'returnToQuizResults') {
      redirectTo = 'quiz/see-results';
    }
    this.loginService.login(credentials).subscribe(
     async (response: any) => {
        if (response && response.header.status === 200 && response.payload) {
          response.payload.user.isRemember = credentials.rememberLogin;
          this.loginService.confirmLoginAndStore(response.payload);
          this.authModalManagerService.closeModal();
          clearForm(this.subscriptionForm);
          if(!this.isQuizPage()){
            this.isLoading = false;
            this.router.navigate([redirectTo], {
              queryParams: {previousPage: 'login'},
            });
          }
          this.isLoading = false;
          await this.cartService.updateCart();
        } else {
          this.attempts++;
          this.isLoading = false;
          this.feedback.setFeedback('Authentication Error. Please, try again!', 'error')
        }
      },
      (response) => {
        this.isLoading = false;
        this.feedback.setFeedback('Login Failed. Please, try again!', 'error')
        this.notificationService.error('Login Failed. Please, try again!');
        throw response;
      }
    );
  }

  async loginWithFaceBook(redirectTo: string = 'uat') {
    this.feedback.hasFeedback = false;
    const response = await this.loginService.loginWithFaceBook();
    const credentials = {
      email: response.email,
      firstName: response.firstName,
      lastName: response.lastName,
    };
    this.isLoading = true;
    if (this.nextPage === 'returnToQuizResults') {
      redirectTo = 'quiz/see-results';
    }
    this.userService.facebookLogin(credentials).subscribe(
     async (response: any) => {
        if (response && response.header.status === 200 && response.payload) {

          this.loginService.confirmLoginAndStore(response.payload);
          this.authModalManagerService.closeModal();
          clearForm(this.subscriptionForm);
          this.isLoading = false;
          this.feedback.setFeedback('Successfully logged in', 'success')

          if(!this.isQuizPage()){
            this.router.navigate([redirectTo], {
              queryParams: {previousPage: 'login'},
            });
          }

          await this.cartService.updateCart();
        } else {
          this.attempts++;
          this.isLoading = false;
          this.feedback.setFeedback('Authentication Error. Please, try again!', 'error')
        }
      },
      (response) => {
        this.isLoading = false;
        this.feedback.setFeedback('Login Failed. Please, try again!', 'error')
        this.notificationService.error('Login Failed. Please, try again!');
        throw response;
      }
    );
  }

  changePasswordView() {
    this.isVisiblePassword = !this.isVisiblePassword;
  }

  goToSignUpView() {
    this.authModalManagerService.updateTypeModal('singUp');
  }

  goToRecoverView(){
    this.authModalManagerService.updateTypeModal('startRecoverPassword');
  }

  private async buildGoogleLoginButton() {
    const btn = this.googleBtn.nativeElement;
    window.google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (token: any) => {
        this.handleCredentialResponse(token);
      },
    });
    window.google.accounts.id.renderButton(
      btn,
      {theme: 'outline', size: 'large'}
    );
    window.google.accounts.id.prompt();
  }

  isQuizPage(){
    const url = this.router.url;
    return url === '/uat/quiz';
  }

  closeModal(){
    this.authModalManagerService.closeModal();
  }
}
