<div class="dialog">
  <div class="recover-password-email" *ngIf="!passwordSend">
    <h2>Reset Password</h2>
    <label>Enter your new password.</label>
    <form [formGroup]="endRecoverPasswordForm" (ngSubmit)="onSubmit()">
      <form-input-password [inputId]="'recoverPassword'" [name]="'password'" [placeholder]="'New password'"
        [fieldControl]="endRecoverPasswordFormControls.password" [errors]="[
        {
          name: 'required',
          message: 'Password is required'
        },
        {
          name: 'pattern',
          message: 'Password must be at least one letter, one number and one special character'
        },
        {
          name: 'minlength',
          message: 'Password must be at least 8 characters'
        },
        ]"
        [isVisiblePassword]="isVisiblePassword"
          (onChangePasswordView)="changePasswordView()"></form-input-password>
        <form-input-password [inputId]="'recoverPasswordConfirm'" [name]="'password'" [placeholder]="'Confirm password'"
        [fieldControl]="endRecoverPasswordFormControls.confirmPassword" [errors]="[
        {
          name: 'required',
          message: 'Confirm Password is required'
        },
        {
          name: 'notSame',
          message: 'Password and confirm not match'
        },
        ]"
        [isVisiblePassword]="isVisibleConfirmPassword"
          (onChangePasswordView)="changeConfirmPasswordView()"></form-input-password>
      <form-button [disabled]="endRecoverPasswordForm.invalid" [isLoading]="isLoading" type="'submit'" [text]="'Send new password'"></form-button>
    </form>
  </div>
  <div class="recover-password-email-sent" *ngIf="passwordSend">
    <h2>Reset Password</h2>
    <!-- <label *ngIf="hasFeedback">{{feedbackMessage}}</label> -->
    <feedback-text [fontSize]="16" [fontWeight]="400" [varient]="feedback.varient" [hidden]="!feedback.hasFeedback"
            [text]="feedback.msg"> </feedback-text>
    <button [hidden]="!feedback.hasFeedback" class="sign-in" (click)="closeRecoverPassword()">Close</button>

      <loading-box *ngIf="passwordSend" [isLoading]="isLoading"></loading-box>

  </div>
  <label class="sign-in-footer" *ngIf="!passwordSend">By continuing, you agree to Curl Lab's
    <a (click)="closeModal()" [routerLink]="['/uat/policy-terms']">Terms of Use and Privacy Policy</a></label>
    <div class="sing-in">
      <label>Go to sign in</label>
      <a (click)="changeSignInView()">SIGN IN</a>
    </div>
</div>
