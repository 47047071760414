import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../environments/environment';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./views/pre-lauch/pre-launch.module').then(
        (m) => m.PreLaunchModule
      ),
  },
  //TODO: After prelaunch, the path 'uat' will be the default route
  environment.production
    ? {
        path: 'uat',
        redirectTo: '',
      }
    : {
        path: 'uat',
        loadChildren: () =>
          import('./views/uat/uat.module').then((m) => m.UatModule),
      },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
