export interface IUserRegister {
  userId?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  birthday?: string;
  gender?: string;
}

export class User {
  userId?: number;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  confirmPassword?: string;
  birthday?: Date;
  legalDocument?: string;
  gender?: string;
  acceptReceiveEmail?: boolean;

  constructor(data: {
    userId: number;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    birthday: Date;
    legalDocument: string;
    gender?: string;
    acceptReceiveEmail?: boolean;
  }) {
    this.userId = data.userId;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.password = data.password;
    this.confirmPassword = data.confirmPassword;
    this.birthday = data.birthday;
    this.legalDocument = data.legalDocument;
    this.acceptReceiveEmail = data.acceptReceiveEmail;
    this.gender = data.gender;
  }

  getInitials?() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  getFullName?() {
    return `${this.firstName || ''} ${this.lastName || ''}`;
  }
}
