import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {LoginService} from 'src/app/core/services/login.service';
import { IStartRecoverPasswordFormFields } from '../../models/Form';
import { validateAllFormFields } from '../../utils/validateAllFormFields';
import { EMAIL_PATTERN } from '../../utils/regexPatterns';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { IStartRecoverPassword } from '../../models/RecoverPassword';

@Component({
  selector: 'app-start-recover-password',
  templateUrl: './start-recover-password.component.html',
  styleUrls: ['./start-recover-password.component.scss'],
})
export class StartRecoverPasswordComponent implements OnInit {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public startRecoverPasswordForm!: FormGroup<IStartRecoverPasswordFormFields>;
  public startRecoverPasswordFormControls!: IStartRecoverPasswordFormFields;
  public email: string = '';
  public emailSent: boolean = false;
  public isLoading = false;

  constructor(private loginService: LoginService, private router: Router, private authModalManagerService: AuthModalManagerService) {
  }

  ngOnInit(): void {
    this.startRecoverPasswordForm = this.initFormGroup();
    this.startRecoverPasswordFormControls = this.startRecoverPasswordForm.controls;
  }

  initFormGroup() {
    return this.startRecoverPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(
        EMAIL_PATTERN
      )]),
    });
  }

  onSubmit() {
    if(this.startRecoverPasswordForm.invalid ||  this.isLoading) {
      validateAllFormFields(this.startRecoverPasswordForm);
      return;
    };
    this.isLoading = true;
    const startRecoverPassword: IStartRecoverPassword = {
      email: this.startRecoverPasswordFormControls.email.value
    }
    this.loginService
      .startForgotPassword(startRecoverPassword)
      .subscribe((response: any) => {
        this.emailSent = true;
        this.isLoading = false;
      });

  }

  closeRecoverPassword() {
    this.authModalManagerService.closeModal();
  }

  async finishRecoverPassword() {
    await this.router.navigate(['/uat']);
  }
  changeSignInView(){
    this.authModalManagerService.updateTypeModal('signIn');
  }

  closeModal(){
    this.authModalManagerService.closeModal();
  }
}
