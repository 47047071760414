import { FormArray, FormControl, FormGroup } from "@angular/forms";

export interface ISignInFormFields {
  email: FormControl;
  password: FormControl;
  rememberLogin: FormControl;
}

export interface ISignUpGenresFields {
  female: FormControl,
  male: FormControl,
  nonBinary: FormControl,
  other: FormControl,
  preferNotToSay: FormControl,
}

export type TSignUpGenresOptions = 'female' | 'male' | 'nonBinary' | 'other' | 'preferNotToSay';

export interface ISingUpFormFields {
  firstName: FormControl,
  lastName: FormControl,
  email: FormControl,
  password: FormControl,
  confirmPassword: FormControl,
  birthday: FormControl,
  gender: FormControl,
  genres: FormGroup<ISignUpGenresFields>
}

export interface ISeeResultsFormFields {
  firstName: FormControl,
  lastName: FormControl,
  email: FormControl,
  acceptReceiveEmail: FormControl
}

export interface IStartRecoverPasswordFormFields {
  email: FormControl
}

export interface IEndRecoverPasswordFormFields {
  password: FormControl,
  confirmPassword: FormControl
}

export interface IPreLaunchFields {
  firstName: FormControl,
  lastName: FormControl,
  email: FormControl,
  acceptReceiveEmail: FormControl
}

export interface IHomeFields {
  fullName: FormControl,
  email: FormControl,
  acceptReceiveEmail: FormControl
}

export interface IPersonalInfoFields {
  firstName: FormControl,
  lastName: FormControl,
  email: FormControl,
}
export interface IEmailMeFields {
  acceptReceiveEmail: FormControl,
}
