<app-modal [openDialog]="isModalOpen" (onClose)="closeModal()">
  <div class="auth-modal-container">
  <container-element [ngSwitch]="currentModalType">
    <app-sign-in *ngSwitchCase="'signIn'" [hasCreateAccountLink]="!hasSeeResult()"></app-sign-in>
    <app-start-recover-password
    *ngSwitchCase="'startRecoverPassword'"
    ></app-start-recover-password>
    <app-sign-up
    *ngSwitchCase="'singUp'"
    ></app-sign-up>
    <see-results *ngSwitchCase="'seeResults'"></see-results>
    <app-end-recover-password *ngSwitchCase="'endRecoverPassword'"></app-end-recover-password>
  </container-element>
  <app-see-results-link *ngIf="hasSeeResult()"></app-see-results-link>
</div>
</app-modal>
