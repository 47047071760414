import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Subscription, lastValueFrom} from 'rxjs';
import {UserService} from 'src/app/core/services/user.service';
import {User} from '../../models/User';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { ISignUpGenresFields, ISingUpFormFields, TSignUpGenresOptions } from '../../models/Form';
import { validateAllFormFields } from '../../utils/validateAllFormFields';
import { EButtonVarient } from '../../models/Button';
import { clearForm } from '../../utils/clearForm';
import { EMAIL_PATTERN, PASSWORD_PATTERN } from '../../utils/regexPatterns';
import { Feedback } from '../../models/Feedback';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  public subscriptionForm!: FormGroup<ISingUpFormFields>;
  public subscriptionFormControls!: ISingUpFormFields;
  public feedback = new Feedback();
  public genresControls!: ISignUpGenresFields;
  public emailAlreadySubscribed = false;
  public submitted = false;
  public success = '';
  public isVisiblePassword = false;
  public isVisibleConfirmPassword = false;
  public isLoading = false;
  public buttonTypes = EButtonVarient;
  public currentDate = '';
  public subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private authModalManagerService: AuthModalManagerService,
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    this.subscriptionForm = this.initilizeForm();
    this.subscriptionFormControls = this.subscriptionForm.controls;
    this.genresControls = this.subscriptionFormControls.genres.controls;
    this.currentDate = this.dateService.formatToISOString(new Date());
    const changeFormSubscription = this.subscriptionForm.valueChanges.subscribe(_ => {
      this.hireFeedbackIfInputValuesChange();
    })
    this.subscriptions.push(changeFormSubscription);
  }

  initilizeForm() {
   return new FormGroup({
      firstName: new FormControl('', [Validators.required,Validators.minLength(2),Validators.maxLength(60)]),
      lastName: new FormControl('', [Validators.minLength(2),Validators.maxLength(60)]),
      email: new FormControl('', [Validators.required, Validators.email,  Validators.pattern(EMAIL_PATTERN)]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          PASSWORD_PATTERN
        )]),
      confirmPassword: new FormControl('', [Validators.required]),
      birthday: new FormControl('', [Validators.required]),
      gender: new FormControl('preferNotToSay'),
      genres: new FormGroup({
        female: new FormControl(false),
        male: new FormControl(false),
        nonBinary: new FormControl(false),
        other: new FormControl(false),
        preferNotToSay: new FormControl(false),
      })

    },
    {validators: [this.confirmPasswordValidatior, this.birthdayDateValidatior]})
  }

  changeGender(code: TSignUpGenresOptions) {
    Object.keys(this.genresControls).forEach((control) =>{
      this.genresControls[control as keyof ISignUpGenresFields].setValue(false)
    })
    this.genresControls[code].setValue(true);
    this.subscriptionFormControls.gender.setValue(code);
    this.subscriptionFormControls.gender.updateValueAndValidity({onlySelf: true});
  }

  getUserFormValue(): User{
    return {
      firstName: this.subscriptionFormControls.firstName.value,
      email: this.subscriptionFormControls.email.value,
      lastName: this.subscriptionFormControls.lastName.value,
      birthday: this.subscriptionFormControls.birthday.value,
      password: this.subscriptionFormControls.password.value,
      gender: this.subscriptionFormControls.gender.value
    }
  }

  async onSubmit() {
    this.feedback.hasFeedback = false;

    if(this.subscriptionForm.invalid ||  this.isLoading) {
      validateAllFormFields(this.subscriptionForm);
      return;
    };

    this.isLoading = true;

    let user = this.getUserFormValue() as User;
    let subscribedUser;
    let subscribeUserCall;

    try {
      subscribeUserCall = this.userService.userRegister(user);
      subscribedUser = await lastValueFrom(subscribeUserCall);
    } catch (e) {
      this.feedback.setFeedback("Server Error! Please contact a support.", 'error');
      console.error(e)
    }

    this.submitted = true;
    this.isLoading = false;
    // stop here if form is invalid
    if (this.subscriptionForm.invalid) {
      return;
    }

    this.success = JSON.stringify(this.subscriptionForm.value);

    if (subscribedUser.exception) {
      this.emailAlreadySubscribed = true;
      this.feedback.setFeedback('Sorry, this email is already subscribed.', 'error');
      return;
    } else {
      this.resetForm();
      this.feedback.setFeedback('Thanks for subscribing! Please check your e-mail.', 'success');
    }
  }

  onlyChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value == '') return null;

      let re = new RegExp('^[a-zA-Z ]*$');
      if (re.test(control.value)) {
        return null;
      } else {
        return { onlyChar: true };
      }
    };
  }

  birthdayDateValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null =>  {
    const birthday = control.get('birthday');
    const inputDate = new Date(birthday?.value);

    if( inputDate > this.dateService.getCurrentDate()){
      birthday?.setErrors({ lessThanToday: true })
      return null;
    }
    if(inputDate < this.dateService.getOldDateLimit()){
      birthday?.setErrors({ veryOldDate: true })
      return null;
    }
    birthday?.setErrors({ veryOldDate: null, lessThanToday: null })
    birthday?.updateValueAndValidity({onlySelf: true});
    return null;
  }

  confirmPasswordValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    if ( password?.value === confirmPassword?.value ){
      confirmPassword?.setErrors({ notSame: null })
      confirmPassword?.updateValueAndValidity({onlySelf: true});
      return null;
    } else {
      confirmPassword?.setErrors({ notSame: true });
      return null;
    }
  };
  changePasswordView() {
    this.isVisiblePassword = !this.isVisiblePassword;
  }
  changeSignInView(){
   this.authModalManagerService.updateTypeModal('signIn');
  }

  changeConfirmPasswordView() {
    this.isVisibleConfirmPassword = !this.isVisibleConfirmPassword;
  }
  hireFeedbackIfInputValuesChange(){
    if(this.feedback.hasFeedback) this.feedback.hasFeedback = false;
  }

  closeModal(){
    this.authModalManagerService.closeModal();
  }

  resetForm(){
    clearForm(this.subscriptionForm);
    this.subscriptionFormControls.gender.setValue('preferNotToSay')
  }

  ngOnDestroy(): void {
   this.subscriptions.forEach((sub) => {
    sub.unsubscribe();
   })
  }
}
