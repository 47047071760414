import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  private static FAVORITE_API_URLS = {
    ADD_TO_FAVORITE: `${environment.apiUrl}/favorite/create`,
    REMOVE_FROM_FAVORITE: `${environment.apiUrl}/favorite/inactivate`,
    GET_ALL_FOR_USER: `${environment.apiUrl}/favorite/get-all-for-user`,
  }

  constructor(private http: HttpClient) {}

  addToFavorite(productId: number): Observable<any> {
    return this.http.post(FavoriteService.FAVORITE_API_URLS.ADD_TO_FAVORITE, { productId });
  }

  removeFromFavorite(favoriteId: number): Observable<any> {
    return this.http.post(FavoriteService.FAVORITE_API_URLS.REMOVE_FROM_FAVORITE, { favoriteId });
  }

  getAllForUser(): Observable<any> {
    return this.http.get(FavoriteService.FAVORITE_API_URLS.GET_ALL_FOR_USER);
  }

}
