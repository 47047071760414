import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { TModalType } from '../../models/Modal';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit, OnDestroy {
  isModalOpen: boolean = false;
  currentModalType: TModalType = 'signIn';
  modalStateSubscription = new Subscription();
  modalTypeSubscription = new Subscription();
  seeResultsLinkSubscription = new Subscription;
  hasSeeResultsLink = false;
  constructor(private authModalManagerService: AuthModalManagerService) { }

  ngOnInit(): void {
    this.modalStateSubscription = this.authModalManagerService.modalState.subscribe((isOpen)=>{
      this.isModalOpen = isOpen;
    });
    this.modalTypeSubscription = this.authModalManagerService.modalType.subscribe((type)=>{
      this.currentModalType = type;
    })
    this.seeResultsLinkSubscription = this.authModalManagerService.seeResultLink.subscribe((hasSeeResultLink) => {
      this.hasSeeResultsLink = hasSeeResultLink;
    })
  }

  closeModal(){
    this.authModalManagerService.closeModal();
  }

  hasSeeResult(){
    const notShow = ['seeResults', 'recoverPassword'];
    return this.hasSeeResultsLink && !notShow.includes(this.currentModalType);
  }

  ngOnDestroy(): void {
    this.modalStateSubscription.unsubscribe();
    this.modalTypeSubscription.unsubscribe();
    this.seeResultsLinkSubscription.unsubscribe();
  }

}
