<div class="dialog">
  <h2>SEE RESULTS</h2>
  <p>We’ll use your email address to save your answers</p>
  <form
    class="dialog__form"
    [formGroup]="userForm"
    (ngSubmit)="onSubmit()"
    enctype="multipart/form-data"
  >
    <form-input
      [inputId]="'seeResultsFirstName'"
      [type]="'text'"
      [name]="'firstName'"
      [placeholder]="'First Name *'"
      [fieldControl]="userFormControls.firstName"
      [errors]="[
        {
          name: 'required',
          message: 'Please enter a first name'
        },
        {
          name: 'minlength',
          message: 'First name must be between 2 and 60 characters long'
        },
        {
          name: 'maxlength',
          message: 'First name must be between 2 and 60 characters long'
        },
      ]"
    ></form-input>
    <form-input
      [inputId]="'seeResultsLastName'"
      [type]="'text'"
      [name]="'lastName'"
      [placeholder]="'Last Name'"
      [fieldControl]="userFormControls.lastName"
      [errors]="[
      {
        name: 'minlength',
        message: 'Last name must be between 2 and 60 characters long'
      },
      {
        name: 'maxlength',
        message: 'Last name must be between 2 and 60 characters long'
      },
      ]"
    ></form-input>
    <form-input
      [inputId]="'seeResultsEmail'"
      [type]="'email'"
      [name]="'email'"
      [placeholder]="'Email address *'"
      [fieldControl]="userFormControls.email"
      [errors]="[
        {
          name: 'required',
          message: 'Email is required'
        },
        {
          name: 'pattern',
          message: 'Email must be a valid email address'
        }
      ]"
    ></form-input>
    <div class="allow-email-updates">
      <form-checkbox [fontSize]="14" [inputId]="'seeResultsCheckboxAllowContact'" [text]="'Keep me posted on curl knowledge'" [fieldControl]="userFormControls.acceptReceiveEmail"></form-checkbox>
    </div>
    <div class="dialog__form__options">
      <form-button [disabled]="userForm.invalid" [isLoading]="isLoading" type="'submit'" [text]="'GET MY RESULTS'"></form-button>
      <div class="w-100 d-flex justify-content-center mb-3">
        <feedback-text [fontWeight]="700" [varient]="feedback.varient" [hidden]="!feedback.hasFeedback" [text]="feedback.msg"> </feedback-text>
      </div>
    </div>
    <div class="dialog__form__account">
      <span>Already have an account?</span>
      <a (click)="goToSignUpView()">RESUME HERE</a>
    </div>
  </form>
  <span class="dialog__acknowledge-policy">
    By clicking ‘GET MY RESULTS’ I acknowledge I have read and agree to the Curl Lab End
    User License and Terms of Service and Privacy Policy.
  </span>
</div>
