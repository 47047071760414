import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services/login.service';
import { LocalStorageHelper } from '../../common/Storage.helper';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
import { UserService } from 'src/app/core/services/user.service';
import { CartService } from 'src/app/core/services/cart.service';
import { NavbarService } from 'src/app/public/services/navbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('navbar') navbarElementRef!: ElementRef<HTMLElement>;
  @Input() isCartOpen: boolean = false;
  @Output() onCloseCart: EventEmitter<any> = new EventEmitter();

  @Input() isLoginOpen: boolean = false;
  @Output() onCloseLogin: EventEmitter<any> = new EventEmitter();

  public curllabLogo: string = 'assets/svg/logo.svg';
  public cartIcon: string = 'assets/svg/shopping-bag-thin.svg';
  public path!: string;
  public profileNotLoggedIcon: string = 'assets/svg/profile-thin.svg';
  public profileLoggedIcon: string = 'assets/svg/profile-thin.svg';
  public isRecoverPassword = false;
  public isLogged = false;
  public isRemember = false;
  public isMenuOpen = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private authModalManagerService: AuthModalManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private navbarService: NavbarService
  ) {}

  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    const navbarElement = this.navbarElementRef.nativeElement;
    const navbarHeight = navbarElement.clientHeight;

    if (window.pageYOffset >= navbarHeight && window.innerWidth > 1180) {
      navbarElement.classList.add('nav--shadow');
    } else {
      navbarElement.classList.remove('nav--shadow');
    }
  }

 async ngOnInit() {
    this.path = this.router.url;
    this.isLogged = this.checkIfUserIsLogged();

    // TODO: it is still necessary to create the application login logic
    const loginSubscription = this.loginService.getUser().subscribe((user)=>{
      if(user && user?.email){
        this.isLogged = true;
        this.changeDetectorRef.detectChanges();
      } else {
        this.isLogged = false;
        this.changeDetectorRef.detectChanges();
      }
    })
    const navbarSubscription = this.navbarService.getIsOpenCart().subscribe((isOpen)=>{
      this.isCartOpen = isOpen;
      this.changeDetectorRef.detectChanges();
    })

    this.subscriptions.push(loginSubscription, navbarSubscription);
  }

  ngOnDestroy(): void {
   this.subscriptions.forEach(s => s.unsubscribe);
  }

  closeCart() {
    this.navbarService.setStatusCart(false);
    this.onCloseCart.emit();
  }

  closeLogin() {
    this.isLoginOpen = false;
    this.onCloseLogin.emit();
  }

  async goToQuiz() {
    await this.router.navigate(['/uat/quiz']);
  }

  closeRecoverPassword() {
    this.isRecoverPassword = false;
  }

  handleClickHamburger() {
    this.isMenuOpen = !this.isMenuOpen;
    this.isLoginOpen = false;
    this.navbarService.setStatusCart(false);
  }

  async handleClickCart() {
    this.isMenuOpen = false;
    this.isLoginOpen = false;
    await this.cartService.updateCartIfExist();
    this.navbarService.setStatusCart(true);
  }

  handleClickProfileNotLoggedIn() {
    this.isLoginOpen = true;
    this.isMenuOpen = false;
    this.navbarService.setStatusCart(false);

    this.authModalManagerService.openModal();
    this.authModalManagerService.updateSeeResultsLink(false);
  }

  checkIfUserIsLogged(){
    const user =  this.userService.getCurrentLoggedUser();
    return !!user;
  }
}
