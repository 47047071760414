<div class="form-input">
  <label *ngIf="label" [for]="inputId">{{ label }}</label>
  <input
  #input
  [name]="name"
  [id]="inputId"
  [placeholder]="placeholder"
  [type]="type"
  [max]="max"
  (blur)="onBlurField()"
  [formControl]="fieldControl"
  [class.invalid]="fieldControl.invalid && fieldControl.touched"
  class="w-100"
  [ngClass]="getInputVarient()"
  (focus)="onFocus($event)"
  (change)="onChange($event)" />

<feedback-text [varient]="'error'" [hidden]="!(fieldControl.invalid && fieldControl.touched)" [text]="getCurrentError()"> </feedback-text>
</div>
