import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent implements OnInit {

  @Input() text = '';
  @Input() fieldControl!: FormControl;
  @Input() inputId = '';
  @Input() fontSize = 16;
  @Input() alignItens: 'center' | 'end' | 'start' = 'center';
  @Input() justifyContent: 'center' | 'end' | 'start' = 'center';
  @Output() onClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleButtonClick(event: Event){
    this.onClick.emit(event);
  }

}
