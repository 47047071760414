import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityInputComponent implements OnInit {
  @Input() currentQuantity: number = 1;
  @Output() quantityChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeQuantity(operation: string) {
    if (operation === 'increment') {
      this.currentQuantity++;
    }
    if (operation === 'decrement') {
      if (this.currentQuantity <= 1) return;
      this.currentQuantity--;
    }

    this.quantityChange.emit(this.currentQuantity);
  }

}
