export class Alert  {
    type: AlertType;
    message: string;

    constructor(data: {
        type: AlertType;
        message: string;
    }) {
        this.type = data.type;
        this.message = data.message;
    }
  }
  
  export enum AlertType {
    Success = 'Success',
    Error = 'Error',
    Info = 'Info',
    Warning = 'Warning'
  }
  