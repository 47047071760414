import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TModalType } from 'src/app/shared/models/Modal';

@Injectable({
  providedIn: 'root'
})
export class AuthModalManagerService {

  modalState = new Subject<boolean>();
  modalType = new Subject<TModalType>();
  seeResultLink = new Subject<boolean>();

  openModal(type: TModalType = 'signIn') {
    this.modalState.next(true);
    this.modalType.next(type);
  }
  closeModal() {
    this.modalState.next(false);
  }
  updateTypeModal(type: TModalType){
    this.modalType.next(type);
  }

  updateSeeResultsLink(value: boolean){
    this.seeResultLink.next(value);
  }

  constructor() { }
}
