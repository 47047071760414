import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthModalManagerService } from 'src/app/core/services/auth-modal-manager.service';
@Component({
  selector: 'app-see-results-link',
  templateUrl: './see-results-link.component.html',
  styleUrls: ['./see-results-link.component.scss']
})
export class SeeResultsLinkComponent implements OnInit {

  constructor(private authModalManagerService: AuthModalManagerService) { }

  ngOnInit(): void {
  }

  goToSeeResultsView(){
    this.authModalManagerService.updateTypeModal('seeResults');
  }

}
