import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UnsubscribeUserService {
  constructor(private http: HttpClient) {}

  unsubscribeUser(unsubscribeCode: string): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('unsubscribeCode', unsubscribeCode);

    const url = `${environment.apiUrl}/user/unsubscribe`;

    return this.http.post(
      url,
      {},
      {
        params: queryParams,
      }
    );
  }
}
