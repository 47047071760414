import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgbModalModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { components } from './components';
import { directives } from './directives';
import { EndRecoverPasswordComponent } from './components/end-recover-password/end-recover-password.component';
import { LoadingBoxComponent } from './components/loading-box/loading-box.component';

@NgModule({
  declarations: [...components, ...directives, EndRecoverPasswordComponent, LoadingBoxComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NgbModalModule,
    NgbRatingModule,
  ],
  exports: [...components, ...directives],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class SharedModule {}
